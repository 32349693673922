import React from 'react';
import Layout from '../components/layout';

function EmailVerificationCard() {
  return (
    <Layout>
    <div className="flex items-center justify-center p-2 bg-slate-100 min-h-screen">
      <div className="bg-white p-6 md:p-10 rounded-lg md:shadow-md w-full max-w-md">
        <h2 className="text-xl md:text-3xl font-bold text-slate-900 mb-6 text-center">Email Verification</h2>
        <p className="text-slate-700 mb-6 text-center">
          Please verify your email address by clicking the verification link we sent to your email.
        </p>
        <div className="flex justify-center">
          <button
            className="bg-slate-900 text-white py-3 px-8 rounded-md hover:bg-slate-800 transition duration-300 transform "
            onClick={() => console.log('Verify Email Button Clicked')}
          >
            Verify Email
          </button>
        </div>
      </div>
    </div>
    </Layout>
  );
}

export default EmailVerificationCard;
