import React from 'react'
import Layout from '../components/layout'
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Products = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState("Sales");
  console.log(selectedCategory);


  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
  return (
    <Layout>
      <div className="min-h-screen bg-gray-50 ">
        <div className="bg-blue-600 py-16 md:py-24 text-center text-white">
          <h1 className="text-xl px-3 md:text-3xl font-bold">All the software you need to run your business</h1>
          <div className="mt-6">
            <input
              type="text"
              placeholder="I'm looking for..."
              value={searchTerm}
              onChange={handleInputChange}
              className="w-2/3 md:w-1/3 px-4 py-2 md:py-3 text-xs text-slate-500 md:text-base  rounded-lg shadow-md focus:outline-none"
            />
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-screen-xl xl:max-w-screen-2xl px-4 py-8 mx-auto mt-10 flex">
          {/* Sidebar */}
          <aside className="w-1/4 hidden md:block">
            <h3 className="text-gray-700 font-bold mb-4">Featured Apps</h3>
            <ul className="space-y-2">
              <li className="text-gray-600 hover:text-blue-600 cursor-pointer" onClick={() => setSelectedCategory("Sales")}>Sales</li>
              <li className="text-gray-600 hover:text-blue-600 cursor-pointer" onClick={() => setSelectedCategory("Marketing")}>Marketing</li>
              <li className="text-gray-600 hover:text-blue-600 cursor-pointer">Commerce and POS</li>
              <li className="text-gray-600 hover:text-blue-600 cursor-pointer">Service</li>
              <li className="text-gray-600 hover:text-blue-600 cursor-pointer">Finance</li>
              <li className="text-gray-600 hover:text-blue-600 cursor-pointer">Email and Collaboration</li>
              <li className="text-gray-600 hover:text-blue-600 cursor-pointer">Human Resources</li>
              <li className="text-gray-600 hover:text-blue-600 cursor-pointer">Security and IT Management</li>
            </ul>
          </aside>

          {/* Featured Apps Section */}
          <main className="w-full md:w-3/4">
            <h2 className="text-xl text-center text-slate-700 font-bold mb-6">Featured Apps</h2>
            {selectedCategory === "Sales" ?
              <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">

                <div className="bg-white p-6 md:shadow-lg md:rounded-lg text-center mx-4 md:mx-0">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-slate-600 text-lg font-bold">Infi Connect</h3>
                    <img
                      src="/inficonnectLogoPrevious.png"
                      alt="Product Catalog Illustration"
                      className="w-auto h-10 rounded-lg transition-transform transform hover:scale-105"
                    />
                  </div>
                  {/* <h3 className="text-slate-600 font-bold text-lg">Infi Connect</h3> */}
                  <p className="text-gray-500 xl:min-h-36 md:min-h-32 min-h-16">
                    A comprehensive platform for connecting vendors and brands, empowering partnership and loyalty management.
                  </p>
                  <Link to="/products/sales/inficonnect">

                    <button className="mt-4 bg-slate-500 hover:bg-slate-600 text-white py-2 px-6 rounded-lg">Try Now</button>
                  </Link>
                </div>

                <div className="bg-white p-6 md:shadow-lg md:rounded-lg text-center mx-4 md:mx-0">
                  <div className="flex justify-between items-center mb-2">

                    <h3 className="text-slate-600 font-bold text-lg">Zero Click</h3>
                    <img
                      src="/zeroclickLogo.svg"
                      alt="Product Catalog Illustration"
                      className="w-auto h-10 rounded-lg transition-transform transform hover:scale-105"
                    />
                  </div>
                  <p className="text-gray-500 xl:min-h-36 md:min-h-32 min-h-16">Powerful accounting platform for growing businesses.</p>
                  <Link to="/products/sales/zeroclick">
                    <button className="mt-4 bg-slate-500 hover:bg-slate-600 text-white py-2 px-6 rounded-lg">Try Now</button>
                  </Link>
                </div>

                <div className="bg-white p-6 md:shadow-lg md:rounded-lg text-center mx-4 md:mx-0">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-slate-600 font-bold text-lg">Express Bazaar</h3>
                    <img
                      src="/expressbazaarLogo.png"
                      alt="Product Catalog Illustration"
                      className="w-auto h-10 rounded-lg transition-transform transform hover:scale-105"
                    />
                  </div>
                  <p className="text-gray-500 xl:min-h-36 md:min-h-32 min-h-16">Your one-stop online marketplace offering a wide range of products from trusted vendors.</p>
                  <Link to="/products/sales/expressbazaar">
                    <button className="mt-4 bg-slate-500 hover:bg-slate-600 text-white py-2 px-6 rounded-lg">Try Now</button>
                  </Link>

                </div>

                <div className="bg-white p-6 md:shadow-lg md:rounded-lg text-center mx-4 md:mx-0">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-slate-600 text-lg font-bold">Qwikco</h3>
                    <img
                      src="/qwikcoLogo.png"
                      alt="Product Catalog Illustration"
                      className="w-auto h-10 rounded-lg transition-transform transform hover:scale-105"
                    />
                  </div>
                  {/* <h3 className="text-slate-600 font-bold text-lg">Infi Connect</h3> */}
                  <p className="text-gray-500 xl:min-h-36 md:min-h-32 min-h-16">
                    A comprehensive platform for connecting vendors and brands, empowering partnership and loyalty management.
                  </p>
                  <Link to="/products/sales/qwikco">
                    <button className="mt-4 bg-slate-500 hover:bg-slate-600 text-white py-2 px-6 rounded-lg">Try Now</button>
                  </Link>
                </div>
              </div>
              : selectedCategory === "Marketing" ?
                <div className="flex justify-center mt-20">
                  <p>No Apps Available</p>

                </div> : null}

          </main>
        </div>
      </div>


    </Layout>
  )
}

export default Products