import logo from "./logo.svg";
import "./App.css";
import RoutesPage from "./routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";

function App() {
  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />
      <RoutesPage />
    </React.Fragment>
  );
}

export default App;
