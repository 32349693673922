import React from 'react'
import Layout from '../components/layout'
import ContactSection from '../screens/contactSection'

const Contact = () => {
  return (
    <Layout>


      {/* <div className=" bg-gray-50 flex flex-col items-center p-4 md:p-8">
        <header className="text-center mt-20 py-5">
          <h1 className="text-3xl md:text-5xl font-extrabold text-indigo-700 mb-2">
            Create Your Custom Product Catalog
          </h1>
          <p className="text-gray-600 text-sm md:text-base">
            Showcase your latest collections in a stylish and interactive format.
          </p>
        </header>

        <main className=" w-full max-w-5xl bg-white shadow-lg rounded-lg p-4 md:p-8">
          <section className="mb-6 md:mb-10 animate-fadeIn transition duration-500 ease-in-out transform ">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Catalog Details</h2>
            <form className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-600 mb-1">Catalog Title</label>
                <input
                  type="text"
                  placeholder="e.g., Summer Collection 2024"
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <label className="block text-gray-600 mb-1">Brand Name</label>
                <input
                  type="text"
                  placeholder="e.g., Urban Fashion Co."
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="md:col-span-2">
                <label className="block text-gray-600 mb-1">Description</label>
                <textarea
                  rows="3"
                  placeholder="Describe your catalog..."
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                ></textarea>
              </div>
            </form>
          </section>

          <section className="mb-6 md:mb-10 animate-slideUp transition duration-500 ease-in-out">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Add Products</h2>
            <div className="space-y-4">
              <div className="flex flex-col md:flex-row items-start md:items-center border border-gray-200 rounded-lg p-4 md:p-6">
                <input
                  type="text"
                  placeholder="Product Name"
                  className="w-full md:w-1/3 p-2 mb-3 md:mb-0 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 md:mr-4"
                />
                <input
                  type="text"
                  placeholder="Product Description"
                  className="w-full md:w-2/3 p-2 mb-3 md:mb-0 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 md:mr-4"
                />
                <input
                  type="number"
                  placeholder="Price ($)"
                  className="w-full md:w-1/4 p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
            </div>
            <div className='text-center md:text-left'>
              <button className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                Add Another Product
              </button>
            </div>
          </section>

          <section className="mb-6 md:mb-10 animate-fadeInUp transition duration-700 ease-in-out">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Preview</h2>
            <div className="border border-gray-300 rounded-lg p-6 bg-gray-50 flex justify-center items-center">
              <p className="text-gray-500">Catalog preview will appear here...</p>
            </div>
          </section>

          <div className="text-center">
            <button className="bg-indigo-700 text-white font-bold py-2 px-6 rounded-lg hover:bg-indigo-800 focus:outline-none focus:ring-4 focus:ring-indigo-500">
              Publish Catalog
            </button>
          </div>
        </main>
      </div> */}
      <ContactSection />
    </Layout>
  )
}

export default Contact