import React, { useState, useRef, useEffect } from "react";
import MegaMenu from "./megaMenu";
import { FaAngleDown, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import CustomButton from "../pages/custombutton";





const Header = ({ className }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [selectedCountry, setSelectedCountry] = useState("IN");

  // const countries = ["IN", "UK", "US", "EU"];

  // const [selectedCountry, setSelectedCountry] = useState("IN");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const countries = [
    { code: "IN", name: "India", flag: "/indiaFlag.png" },
    { code: "UK", name: "United Kingdom", flag: "/unitedKingdomFlag.png" },
    { code: "US", name: "United States", flag: "/unitedstatesFlag.png" },
    { code: "EU", name: "Europe", flag: "/europeFlag.png" },
  ];

  const handleChange = (countryCode) => {
    setSelectedCountry(countryCode);
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  const selectedCountryData = countries.find(
    (country) => country.code === selectedCountry
  );


  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const location = useLocation();
  const isInficonnectPage = location.pathname.includes('/inficonnect');

  return (
    <header className={` ${className} z-30 w-full border-b sticky top-0`}>
      <nav className="bg-slate-50 p-2 hidden text-sm border border-slate-200 md:flex">
        <div className="container mx-auto max-w-screen-xl xl:max-w-screen-2xl px-4">
          <div className="flex font-medium lg:flex-row lg:space-x-8 justify-between" id="mobile-menu-2">
            <div>
              <ul className="flex font-normal lg:flex-row lg:space-x-8">
                <li>
                  <Link
                    className="block py-2 pl-3 pr-4 text-[#9D9D9D] border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0  lg:p-0"
                    to="#"
                  >
                    Help Center
                  </Link>
                </li>
                <li>
                  <Link
                    className="block py-2 pl-3 pr-4 text-[#9D9D9D] border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0  lg:p-0"
                    to="https://developer.fixall.ai"
                  >
                    Developer Center
                  </Link>
                </li>
                <li>
                  <Link
                    className="block py-2 pl-3 pr-4 text-[#9D9D9D] border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0  lg:p-0"
                    to="/contact"
                  >
                    Contact
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className="block py-2 pl-3 pr-4 text-[#9D9D9D] border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                    to="#"
                  >
                     Call Sales: 1-888-248-9325
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="flex lg:space-x-8">
              {/* <ul className="flex font-normal lg:flex-row lg:space-x-8">
                <li>
                  <Link
                    className="block py-2 pl-3 pr-4 text-[#9D9D9D] border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:p-0"
                    to="#"
                  >
                    Sign In
                  </Link>
                </li>
              </ul> */}
              {/* <select
                id="country"
                name="country"
                value={selectedCountry}
                onChange={handleChange}
                className="px-2 text-[#9D9D9D] bg-slate-50  rounded-md focus:outline-none"
              >
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select> */}

              <div className="relative py-2 lg:py-0">
                <div className="relative w-full">
                  <div
                    className="px-4 bg-slate-50 flex items-center justify-between cursor-pointer"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    <div className="flex items-center gap-2 text-sm font-normal text-[#9D9D9D]">
                      <img
                        src={selectedCountryData.flag}
                        alt="flag"
                        className="h-3 w-5"
                      />
                      {selectedCountryData.code}
                      {isDropdownOpen ? <IoIosArrowUp className="mt-1" /> : <IoIosArrowDown className="mt-1" />}



                    </div>
                  </div>

                  {isDropdownOpen && (
                    <div
                      className="absolute bg-slate-50 w-full border rounded-sm lg:mt-2"
                    >
                      {countries.map((country) => (
                        <div
                          key={country.code}
                          onClick={() => handleChange(country.code)}
                          className="flex items-center px-4 py-2 cursor-pointer text-sm font-normal text-[#9D9D9D] hover:bg-slate-200"
                        >
                          <img
                            src={country.flag}
                            alt="flag"
                            className="h-3 w-5  mr-2"
                          />
                          {country.code}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <nav className="bg-white py-6">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl xl:max-w-screen-2xl px-4 mx-auto">
          <div className="flex items-center gap-10">
            <div className="flex gap-1 items-center">
              <Link className="flex items-center" to="/">
                <img src="/logo.jpg" alt="Logo" className="h-8 md:h-10" />
              </Link>
              {isInficonnectPage ?
                <div className="h-7">
                  <div className="w-[2px] h-full bg-slate-400"></div>
                </div> : null}

              {isInficonnectPage ? <div>
                <Link to="/products/sales/inficonnect">
                  <img src="/inficonnectLogo.png" alt="Logo" className="h-7" />
                </Link>
              </div>
                : null}
            </div>

            <div className="hidden lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
              <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                <li className="" ref={menuRef}>
                  <div>
                    <button
                      onClick={toggleMenu}
                      className="flex items-center text-gray-700 hover:text-purple-700 focus:outline-none"
                    >
                      Products <FaAngleDown className="ml-2" />
                    </button>
                    <MegaMenu isOpen={isMenuOpen} />
                  </div>
                </li>
                <li>
                  <Link
                    className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                    to="/enterprise"
                  >
                    Enterprise
                  </Link>
                </li>
                <li>
                  <Link
                    className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                    to="/customers"
                  >
                    Customers
                  </Link>
                </li>
                {/* <li>
                <Link
                  className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                  to="/company"
                >
                  Company
                </Link>
              </li> */}
              </ul>
            </div>
          </div>
          <div className="flex lg:order-2">
            {/* <div className="space-x-4 hidden md:flex">
              <Link
                to="/request-demo"
                className="bg-[#7453E0] text-white font-semibold px-4 py-2 rounded border-[1px]"
              >
                Request a Demo
              </Link>
            </div> */}
            <div className="hidden md:flex">
            <CustomButton to="/request-demo" label="Request a Demo" showArrow={false}  marginTop ="0"/>
            </div>

            <button
              aria-controls="mobile-menu-2"
              aria-expanded={isMobileMenuOpen}
              onClick={toggleMobileMenu}
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              type="button"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  fillRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

        </div>
      </nav>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 z-40 bg-black bg-opacity-50 flex justify-end lg:hidden">
          <div
            className={`transform top-0 right-0 w-64 bg-white h-full shadow-lg transition-transform duration-300 ease-in-out ${isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
              }`}
          >
            <button
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 focus:outline-none"
              onClick={closeMobileMenu}
            >
              <FaTimes className="w-6 h-6" />
            </button>
            <ul className="p-6 space-y-4">
              <li>
                <Link
                  className="block text-gray-700 hover:text-purple-700"
                  to="/products"
                  onClick={closeMobileMenu}
                >
                  Products
                </Link>
              </li>
              <li>
                <Link
                  className="block text-gray-700 hover:text-purple-700"
                  to="/enterprise"
                  onClick={closeMobileMenu}
                >
                  Enterprise
                </Link>
              </li>
              <li>
                <Link
                  className="block text-gray-700 hover:text-purple-700"
                  to="/customers"
                  onClick={closeMobileMenu}
                >
                  Customers
                </Link>
              </li>
              <li>
                <Link
                  className="block text-gray-700 hover:text-purple-700"
                  to="https://developer.fixall.ai"
                  onClick={closeMobileMenu}
                >
                  Developer Center
                </Link>
              </li>
              <li>
                <Link
                  className="block text-gray-700 hover:text-purple-700"
                  to="/contact"
                  onClick={closeMobileMenu}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  className="bg-[#7453E0] text-sm text-white font-semibold  px-4 py-2 rounded"
                  to="/request-demo"
                  onClick={closeMobileMenu}
                >
                  Request a Demo
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;