import React from 'react'
import Layout from '../components/layout'
import { Link } from 'react-router-dom'

const Expressbazaar = () => {
  return (
    <Layout>
      <div className='w-full h-[90vh] flex justify-center align-middle items-center'> 
        <img src="/comingSoon.jpg" className="w-[50%] h-auto"></img>
      </div>
    </Layout>
  )
}

export default Expressbazaar