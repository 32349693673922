import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';



const RequestDemo = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    product: "",
    phone: "",
    country: "",
    businessType: "",
    description: "",
  });

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  const [selectedDate, setSelectedDate] = useState("");
  const [timeOptions, setTimeOptions] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");

  // Generate date options
  const generateDateOptions = () => {
    const options = [];
    const today = new Date();
    options.push({ label: "Today", value: today.toISOString().split("T")[0] });

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    options.push({ label: "Tomorrow", value: tomorrow.toISOString().split("T")[0] });

    for (let i = 2; i <= 4; i++) {
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + i);
      options.push({
        label: futureDate.toDateString(),
        value: futureDate.toISOString().split("T")[0],
      });
    }

    return options;
  };

  // Generate time options
  const generateTimeOptions = (selected) => {
    const now = new Date();
    const currentHour = now.getHours();
    const startHour = 9;
    const endHour = 19;

    const options = [];
    for (let hour = startHour; hour <= endHour; hour++) {
      if (
        selected === new Date().toISOString().split("T")[0] && // If "Today" is selected
        hour <= currentHour
      )
        continue;

      options.push(`${hour}:00`);
    }
    return options;
  };

  // Handle date change
  const handleDateSelection = (value) => {
    setSelectedDate(value);
    const updatedTimeOptions = generateTimeOptions(value);
    setTimeOptions(updatedTimeOptions);
    setSelectedTime("");
  };

  // Handle time change
  const handleTimeSelection = (value) => {
    setSelectedTime(value);
  };

  // Date options for cards
  const dateOptions = generateDateOptions();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      // Allow only numbers and limit to 10 digits
      const validatedValue = value.replace(/[^0-9]/g, "").slice(0, 10);
      setFormData({ ...formData, [name]: validatedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false); // Hide popup after 3 seconds
        navigate('/'); // Navigate to home page after the popup disappears
      }, 3000); // 3 seconds

      // Cleanup the timer
      return () => clearTimeout(timer);
    }
  }, [showPopup, navigate]);


  const dataToSend = {
    firstName: formData.firstName,
    lastName: formData.lastName,
    businessEmail: formData.email,
    companyName: formData.companyName,
    product: formData.product,
    phone: formData.phone,
    country: formData.country,
    businessType: formData.businessType,
    description: formData.description,
    scheduleDate: selectedDate,
    scheduleTime: selectedTime,
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    console.log("dataToSend is:", dataToSend);


    try {
      const response = await axios.post(
        "https://catalog-pro-engine.onrender.com/admin/v1/demo/create",
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.data?.success === true) {
        // toast.success(response?.data?.message || "Success");
        // setResponseMessage(response?.data?.message || "Success");
        setShowPopup(true);
        console.log("Response:", response.data);
        setLoading(false);
      } else {
        toast.error(response?.data?.message || "An error occurred. Please try again.");
        // setResponseMessage(response?.data?.message || "An error occurred. Please try again.");
        setLoading(false);

      }


    } catch (error) {
      // setResponseMessage("Error submitting the demo request.");
      console.error("Error:", error.response?.data || error.message);
    } finally {
      setLoading(false);
    }




  };

  return (
    <Layout>
      <div className="min-h-screen flex flex-col lg:flex-row bg-gradient-to-b from-blue-600 to-blue-400 text-white p-4 md:p-6 lg:p-12">
        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 flex flex-col items-center rounded shadow-lg text-slate-600 gap-8">
              <h2 className="text-xl font-semibold text-center text-green-500">
                Demo requested successfully!
              </h2>
              <p className="text-center mt-2">We have sent you the mail regarding this.</p>
              <Link to="/">
                <button className="py-2 px-4 border border-slate-900 text-slate-900 bg-white hover:text-white hover:bg-slate-900 rounded-md font-semibold text-sm">Go to Home</button>
              </Link>
            </div>
          </div>
        )}
        {/* Left Section */}
        {/* <div className="lg:w-1/2 flex flex-col  items-start space-y-6 "> */}
        <div className="lg:sticky top-[40%] left-0 lg:h-screen lg:w-1/2 flex flex-col gap-14 items-start">
          <h1 className="text-3xl md:text-6xl font-medium tracking-wide">Talk to our sales<br/> team.</h1>
          <p className="text-lg lg:w-[60%]">
            Let's explore your brand together — and craft a plan for how our
            ecommerce platform can help you boost revenue and unlock growth.
          </p>
          <img
            // src="https://via.placeholder.com/500x300"
            src="/bookDemoVectorImage.png"
            alt="Sales"
            className="rounded-md w-96"
          />
          <div className="flex space-x-4">
            {/* <img src="/inficonnect/inficonnectLogo.png" alt="Brand Logo" className="w-28"/> */}
            {/* <img src="https://via.placeholder.com/80x40" alt="Brand Logo" /> */}
          </div>
        </div>

        {/* Right Section */}
        {/* <div className="lg:w-1/2 bg-white  text-gray-900 rounded-lg p-8 shadow-lg"> */}
        <div className="lg:w-1/2 bg-white text-gray-900 rounded-lg p-8 shadow-lg overflow-y-auto">

          <h2 className="text-xl font-semibold mb-7 tracking-wider">Fill out the form below to get started</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="p-3 border rounded-lg w-full"
                  required
                />
              </div>
              <div>
                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="p-3 border rounded-lg w-full"
                  required
                />
              </div>
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Business Email Address</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Business Email Address"
                value={formData.email}
                onChange={handleInputChange}
                className="p-3 border rounded-lg w-full"
                required
              />
            </div>
            <div>
              <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">Company Name</label>
              <input
                type="text"
                name="companyName"
                id="companyName"
                placeholder="Company Name"
                value={formData.companyName}
                onChange={handleInputChange}
                className="p-3 border rounded-lg w-full"
                required
              />
            </div>
            <div>
              <label htmlFor="product" className="block text-sm font-medium text-gray-700">Select Product</label>
              <select
                name="product"
                id="product"
                value={formData.product}
                onChange={handleInputChange}
                className="p-3 border rounded-lg w-full"
                required
              >
                <option value="" disabled>Select Product</option>
                <option value="inficonnect">Inficonnect</option>
                <option value="zeroClick">Zero Click</option>
                <option value="expressBazaar">Express Bazaar</option>
              </select>
            </div>
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone Number</label>
              <input
                type="tel"
                name="phone"
                id="phone"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handleInputChange}
                className="p-3 border rounded-lg w-full"
                required
              />
            </div>
            <div>
              <label htmlFor="country" className="block text-sm font-medium text-gray-700">Country</label>
              <select
                name="country"
                id="country"
                value={formData.country}
                onChange={handleInputChange}
                className="p-3 border rounded-lg w-full"
                required
              >
                <option value="" disabled>Country</option>
                <option value="USA">USA</option>
                <option value="India">India</option>
                <option value="Canada">Canada</option>
              </select>
            </div>
            <div>
              <label htmlFor="businessType" className="block text-sm font-medium text-gray-700">Business Type</label>
              <select
                name="businessType"
                id="businessType"
                value={formData.businessType}
                onChange={handleInputChange}
                className="p-3 border rounded-lg w-full"
                required
              >
                <option value="" disabled>Business Type</option>
                <option value="Retail">Retail</option>
                <option value="Wholesale">Wholesale</option>
                <option value="Services">Services</option>
              </select>
            </div>
            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
              <textarea
                name="description"
                id="description"
                placeholder="Description"
                value={formData.description}
                onChange={handleInputChange}
                className="p-3 border rounded-lg w-full"
                required
              />
            </div>


            {/* <div className="mb-4">
              <label className="block mb-2 text-sm font-medium">Select Date:</label>
              <select
                value={selectedDate}
                onChange={handleDateChange}
                className="border rounded px-3 py-2 w-full"
                required
              >
                <option value="" disabled>
                  Choose a date
                </option>
                {dateOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div> */}

            {/* {selectedDate && (
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium">Select Time:</label>
                <select
                  value={selectedTime}
                  onChange={handleTimeChange}
                  className="border rounded px-3 py-2 w-full"
                  required
                >
                  <option value="" disabled>
                    Choose a time
                  </option>
                  {timeOptions.map((time, index) => (
                    <option key={index} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
              </div>
            )} */}


            {/* Date Selection */}
            <div className="mb-6">
              <h2 className="mb-4 text-lg font-semibold">Select Date:</h2>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {dateOptions.map((option, index) => (
                  <div
                    key={index}
                    onClick={() => handleDateSelection(option.value)}
                    className={`p-2 md:p-4 border text-sm md:text-base rounded-lg text-center cursor-pointer ${selectedDate === option.value
                      ? "bg-teal-500 text-white"
                      : "bg-gray-100 hover:bg-gray-200"
                      }`}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            </div>

            {/* Time Selection */}
            {selectedDate && (
              <div className="mb-6">
                <h2 className="mb-4 text-lg font-semibold">Select Time:</h2>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  {timeOptions.map((time, index) => (
                    <div
                      key={index}
                      onClick={() => handleTimeSelection(time)}
                      className={`p-2 md:p-4 border rounded-lg text-center cursor-pointer ${selectedTime === time
                        ? "bg-teal-500 text-white"
                        : "bg-gray-100 hover:bg-gray-200"
                        }`}
                    >
                      {time}
                    </div>
                  ))}
                </div>
              </div>
            )}



            <button
              type="submit"
              className={`w-full p-3 text-white rounded-lg ${loading ? "bg-gray-400" : "bg-blue-600 hover:bg-blue-700"}`}
              disabled={loading}
            >
              {loading ? "Submitting..." : "Get in Touch"}
            </button>
          </form>
        </div>
      </div>
    </Layout>

  );
};

export default RequestDemo;
