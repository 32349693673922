import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from '../pages/home'
import Products from '../pages/products'
import NotFound from '../pages/notFound'
import SignUp from '../pages/signup'
import Contact from '../pages/contact'
import Inficonnect from '../pages/inficonnect'
import EmailVerification from '../pages/emailVerification'
import Enterprise from '../pages/enterprise'
import Customers from '../pages/customers'
import RequestDemo from '../pages/requestDemo.js'
import Zeroclick from '../pages/zeroclick.js'
import Expressbazaar from '../pages/expressbazaar.js'
import Qwikco from '../pages/qwikco.js'
import Ourstory from '../pages/ourstory.js'

const RoutesPage = () => {
  return (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/products' element={<Products/>}/>
            <Route path='/products/sales/inficonnect' element={<Inficonnect/>}/>
            <Route path='/products/sales/zeroclick' element={<Zeroclick/>}/>
            <Route path='/products/sales/expressbazaar' element={<Expressbazaar/>}/>
            <Route path='/products/sales/qwikco' element={<Qwikco/>}/>
            <Route path='/products/sales/inficonnect/signup' element={<SignUp/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/request-demo' element={<RequestDemo/>}/>
            <Route path='/enterprise' element={<Enterprise/>}/>
            <Route path='/customers' element={<Customers/>}/>
            <Route path='/thankyou' element={<EmailVerification/>}/>
            <Route path='/ourstory' element={<Ourstory/>}/>
            <Route path='*' element={<NotFound/>}/>
        </Routes>
    </BrowserRouter>
  )
}

export default RoutesPage