import React, { useEffect, useRef, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Layout from "../components/layout";
import HeroSection from "../screens/heroSection";
import Plans from "../screens/plans";
import { Link } from "react-router-dom";
import { MdOutlineChevronRight } from "react-icons/md";
import HeroVideoSection from "../screens/heroVideoSection";
import BrandSection from "../components/brandSection";
import "./cardanimation.css"
import CustomButton from "./custombutton";


const Home = () => {

  const items = [
    {
      quote:
        "You can be a startup, mid-sized company, or an enterprise—Fixall One is a boon for all.",
      name: "Shubham Sharma",
      title: "CEO, 5paisa.com (an IIFL subsidiary)",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqcVXIgWCvTbb55lDj91N_g2rd0F3rma21CA&s",
    },
    {
      quote: "Fixall One is the future of business automation.",
      name: "John Doe",
      title: "CTO, TechCorp",
      img: "https://via.placeholder.com/50",
    },
    {
      quote: "An amazing tool for managing complex workflows.",
      name: "Jane Smith",
      title: "Product Manager, Workflow Inc.",
      img: "https://via.placeholder.com/50",
    },

  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);

  const clonedItems = [...items, ...items];

  console.log("clonedItems.length", clonedItems.length)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const totalItems = clonedItems.length;
    const scrollContainer = containerRef.current;

    if (currentIndex === totalItems) {
      setTimeout(() => {
        scrollContainer.style.transition = "none"; // Disable transition
        setCurrentIndex(0);
      }, 1000);
    } else {
      scrollContainer.style.transition = "transform 1s ease"; // Smooth transition
    }
  }, [currentIndex, items.length]);


  return (
    <Layout>
      <HeroVideoSection />
      <BrandSection />
      {/* <HeroSection /> */}
      {/* <section className="text-center py-16 bg-gray-50">
        <h1 className="text-4xl font-bold">
          Elevate Sales With Seamless Management
        </h1>
        <p className="mt-4 text-lg">
          Empower your team, streamline your processes, and boost revenue with
          our platform.
        </p>
        <div className="mt-6 space-x-4">
          <button className="bg-green-500 text-white px-6 py-3 rounded">
            Get Started
          </button>
          <button className="bg-white text-green-500 px-6 py-3 border border-green-500 rounded">
            Play Video
          </button>
        </div>
        <div className="mt-12 grid grid-cols-3 gap-6">
          <img src="/path-to-image1.png" alt="Sales Activity" />
          <img src="/path-to-image2.png" alt="Team Work" />
          <img src="/path-to-image3.png" alt="Laptop Screen" />
        </div>
      </section> */}
      {/* <Plans /> */}

      {/* Products Section */}
      {/* <section className="bg-gray-50 py-6">
        <div className="w-full max-w-screen-xl px-4 mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="p-6 border flex flex-col justify-evenly rounded-lg cursor-pointer bg-white hover:shadow-lg transition-transform transform hover:scale-105 aspect-square">
              <div className="flex justify-between items-center mb-4">
                <h4 className="text-xl font-semibold text-gray-700 group-hover:text-blue-600">
                  Infi Connect
                </h4>
                <img
                  src="/inficonnect/logo.png"
                  alt="Product Catalog Illustration"
                  className="w-12 h-auto rounded-lg transition-transform transform group-hover:scale-110"
                />
              </div>
              <p className="text-sm text-gray-600">
                A comprehensive platform for connecting vendors and brands, empowering partnership and loyalty management.
              </p>
              <Link to="/products/sales/inficonnect">
                <button className="bg-white  mx-auto text-slate-900 font-semibold hover:bg-slate-900 hover:text-white px-4 py-2 rounded border-[1px] border-slate-900">
                  Explore
                </button>
              </Link>
            </div>
            <div className="p-6 border flex flex-col justify-evenly rounded-lg cursor-pointer bg-white hover:shadow-lg transition-transform transform hover:scale-105 aspect-square">
              <h4 className="text-xl font-semibold text-gray-700 hover:text-blue-600">
                Zero Click
              </h4>
              <p className="text-sm text-gray-600 mt-2">
                Complete loyalty and affiliate management platform.
              </p>
              <Link to="">
                <button className="bg-white  mx-auto text-slate-900 font-semibold hover:bg-slate-900 hover:text-white px-4 py-2 rounded border-[1px] border-slate-900">
                  Explore
                </button>
              </Link>
            </div>
            <div className="p-6 border flex flex-col justify-evenly rounded-lg cursor-pointer bg-white hover:shadow-lg transition-transform transform hover:scale-105 aspect-square">
              <h4 className="text-xl font-semibold text-gray-700 hover:text-blue-600">
                Express Bazaar
              </h4>
              <p className="text-sm text-gray-600 mt-2">
                Your one-stop online marketplace offering a wide range of products from trusted vendors.
              </p>
              <Link to="">
                <button className="bg-white  mx-auto text-slate-900 font-semibold hover:bg-slate-900 hover:text-white px-4 py-2 rounded border-[1px] border-slate-900">
                  Explore
                </button>
              </Link>
            </div>
            <div className="p-6 border flex flex-col justify-evenly rounded-lg cursor-pointer bg-white hover:shadow-lg transition-transform transform hover:scale-105 aspect-square">
              <div className="flex justify-between items-center mb-4">
                <h4 className="text-xl font-semibold text-gray-700 group-hover:text-blue-600">
                  Qwikco
                </h4>
                <img
                  src="/qwikcoLogo.png"
                  alt="Product Catalog Illustration"
                  className="w-10 h-auto rounded-lg transition-transform transform group-hover:scale-110"
                />
              </div>
              <p className="text-sm text-gray-600">
                A comprehensive platform for connecting vendors and brands, empowering partnership and loyalty management.
              </p>
              <Link to="">
                <button className="bg-white  mx-auto text-slate-900 font-semibold hover:bg-slate-900 hover:text-white px-4 py-2 rounded border-[1px] border-slate-900">
                  Explore
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="text-center mt-6">
          <Link to="/products">
            <button className="bg-white text-slate-900 font-semibold hover:bg-slate-900 hover:text-white px-4 py-2 rounded border-[1px] border-slate-900">
              Explore All Products
            </button>
          </Link>
        </div>
      </section> */}

      {/* Products Section */}
      <section className=" py-6 bg-[#F7F5FF]">
        <div className="w-full max-w-screen-xl xl:max-w-screen-2xl p-4 mx-auto text-center">
          <div className="py-5">
            {/* <div className="">
              <p className="font-medium ">COMMERCE SOLUTIONS</p>
            </div> */}
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-medium">Everything you need for growth</h1>
          </div>

          {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 mt-7">
            <div className="group p-6 border flex flex-col text-left justify-evenly rounded-3xl cursor-pointer bg-gradient-to-t from-[#e0f6f7] to-[#a6e8ec] hover:shadow-lg transition-transform transform hover:translate-y-[-10px] aspect-square">
              <img
                alt="B2B Icon"
                loading="lazy"
                width="48"
                height="48"
                decoding="async"
                data-nimg="1"
                src="https://storage.googleapis.com/s.mkswft.com/RmlsZTo5ODMyMTg1OC1hM2YzLTQ1ODEtYWY0NC0yOTEyYTQzMzFiNTA=/icon-b2b.svg"
                className="text-transparent"
              />
              <p className="text-xl font-semibold text-gray-700">
                B2B
              </p>
              <svg
                height="16"
                width="20"
                fill="currentColor"
                viewBox="0 0 20 16"
                className="ml-2 group-hover:translate-x-[5px] transition-transform duration-150"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.138 8.84H.84a.839.839 0 1 1 0-1.679h16.354L13.508 3.42a.839.839 0 1 1 1.213-1.16l5.044 5.14a.84.84 0 0 1-.013 1.173l-5.044 5.182a.839.839 0 1 1-1.187-1.186l3.617-3.729Z"
                ></path>
              </svg>
            </div>
            <div className="group p-6 border flex flex-col text-left justify-evenly rounded-3xl cursor-pointer bg-gradient-to-t from-[#e0f3f9] to-[#a7ddf1] hover:shadow-lg transition-transform transform hover:translate-y-[-10px] aspect-square">
              <img
                alt="Headless Icon"
                loading="lazy"
                width="48"
                height="48"
                decoding="async"
                data-nimg="1"
                src="https://storage.googleapis.com/s.mkswft.com/RmlsZTpiMjA3Yzg1MS1mY2FjLTRkMjMtYjBkOC03NjEwYTlhMDQxZWM=/icon-headless.svg"
                className="text-transparent"
              />
              <p className="text-xl font-semibold text-gray-700">
                Headless
              </p>
              <svg
                height="16"
                width="20"
                fill="currentColor"
                viewBox="0 0 20 16"
                className="ml-2 group-hover:translate-x-[5px] transition-transform duration-150"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.138 8.84H.84a.839.839 0 1 1 0-1.679h16.354L13.508 3.42a.839.839 0 1 1 1.213-1.16l5.044 5.14a.84.84 0 0 1-.013 1.173l-5.044 5.182a.839.839 0 1 1-1.187-1.186l3.617-3.729Z"
                ></path>
              </svg>
            </div>
            <div className="group p-6 border flex flex-col text-left justify-evenly rounded-3xl cursor-pointer bg-gradient-to-t from-[#e0eefb] to-[#a8d2f5] hover:shadow-lg transition-transform transform hover:translate-y-[-10px] aspect-square">
              <img
                alt="B2B Icon"
                loading="lazy"
                width="48"
                height="48"
                decoding="async"
                data-nimg="1"
                src="https://storage.googleapis.com/s.mkswft.com/RmlsZTo5OTlmYTAzMi0wN2RiLTQxMTAtOWQwYy02MjdjNzA0ZDBlNzQ=/icon-multi-storefront.svg"
                className="text-transparent"
              />
              <p className="text-xl font-semibold text-gray-700">
                Multi-Storefront
              </p>
              <svg
                height="16"
                width="20"
                fill="currentColor"
                viewBox="0 0 20 16"
                className="ml-2 group-hover:translate-x-[5px] transition-transform duration-150"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.138 8.84H.84a.839.839 0 1 1 0-1.679h16.354L13.508 3.42a.839.839 0 1 1 1.213-1.16l5.044 5.14a.84.84 0 0 1-.013 1.173l-5.044 5.182a.839.839 0 1 1-1.187-1.186l3.617-3.729Z"
                ></path>
              </svg>
            </div>
            <div className="group p-6 border flex flex-col text-left justify-evenly rounded-3xl cursor-pointer bg-gradient-to-t from-[#e0eafb] to-[#a9c7fa] hover:shadow-lg transition-transform transform hover:translate-y-[-10px] aspect-square">
              <img
                alt="Omnichannel Icon"
                loading="lazy"
                width="48"
                height="48"
                decoding="async"
                data-nimg="1"
                src="https://storage.googleapis.com/s.mkswft.com/RmlsZTphYzBlYTUyZC0zMDJiLTQ3NzQtOTg0Ni1mMWNkMTE5MmE5OGM=/icon-omnichannel.svg"
                className="text-transparent"
              />
              <p className="text-xl font-semibold text-gray-700">
                Omnichannel
              </p>
              <svg
                height="16"
                width="20"
                fill="currentColor"
                viewBox="0 0 20 16"
                className="ml-2 group-hover:translate-x-[5px] transition-transform duration-150"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.138 8.84H.84a.839.839 0 1 1 0-1.679h16.354L13.508 3.42a.839.839 0 1 1 1.213-1.16l5.044 5.14a.84.84 0 0 1-.013 1.173l-5.044 5.182a.839.839 0 1 1-1.187-1.186l3.617-3.729Z"
                ></path>
              </svg>
            </div>
            <div className="group p-6 border flex flex-col text-left justify-evenly rounded-3xl cursor-pointer bg-gradient-to-t from-[#e3eefc] to-[#b8d3fa] hover:shadow-lg transition-transform transform hover:translate-y-[-10px] aspect-square">
              <img
                alt="Wholesale Icon"
                loading="lazy"
                width="48"
                height="48"
                decoding="async"
                data-nimg="1"
                src="https://storage.googleapis.com/s.mkswft.com/RmlsZTo5MTU2ZDdkNS1iMDkxLTQ5ZDMtODMwOC1iNTdhODE5NDgyZDk=/icon-wholesale.svg"
                className="text-transparent"
              />
              <p className="text-xl font-semibold text-gray-700">
                Wholesale
              </p>
              <svg
                height="16"
                width="20"
                fill="currentColor"
                viewBox="0 0 20 16"
                className="ml-2 group-hover:translate-x-[5px] transition-transform duration-150"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.138 8.84H.84a.839.839 0 1 1 0-1.679h16.354L13.508 3.42a.839.839 0 1 1 1.213-1.16l5.044 5.14a.84.84 0 0 1-.013 1.173l-5.044 5.182a.839.839 0 1 1-1.187-1.186l3.617-3.729Z"
                ></path>
              </svg>
            </div>
            <div className="group p-6 border flex flex-col text-left justify-evenly rounded-3xl cursor-pointer bg-gradient-to-t from-[#e3e7fb] to-[#b6c4fa] hover:shadow-lg transition-transform transform hover:translate-y-[-10px] aspect-square">
              <img
                alt="Offline-to-Online Icon"
                loading="lazy"
                width="48"
                height="48"
                decoding="async"
                data-nimg="1"
                src="https://storage.googleapis.com/s.mkswft.com/RmlsZTo0NjdhMTAwNS01NTFhLTRkYWUtYmM2Ni0yNTVhM2YyY2Y1N2M=/icon-offline-online.svg"
                className="text-transparent"
              />
              <p className="text-xl font-semibold text-gray-700">
                Offline to Online
              </p>
              <svg
                height="16"
                width="20"
                fill="currentColor"
                viewBox="0 0 20 16"
                className="ml-2 group-hover:translate-x-[5px] transition-transform duration-150"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.138 8.84H.84a.839.839 0 1 1 0-1.679h16.354L13.508 3.42a.839.839 0 1 1 1.213-1.16l5.044 5.14a.84.84 0 0 1-.013 1.173l-5.044 5.182a.839.839 0 1 1-1.187-1.186l3.617-3.729Z"
                ></path>
              </svg>
            </div>
            <div className="group p-6 border flex flex-col text-left justify-evenly rounded-3xl cursor-pointer bg-gradient-to-t from-[#e5e5f9] to-[#bdbbf3] hover:shadow-lg transition-transform transform hover:translate-y-[-10px] aspect-square">
              <img
                alt="International Icon"
                loading="lazy"
                width="48"
                height="48"
                decoding="async"
                data-nimg="1"
                src="https://storage.googleapis.com/s.mkswft.com/RmlsZTozM2ZjZmY0My1lYTc3LTQzMmMtOTljNi02ZmFjMTI1YWI2Y2U=/icon-international.svg"
                className="text-transparent"
              />
              <p className="text-xl font-semibold text-gray-700">
                International
              </p>
              <svg
                height="16"
                width="20"
                fill="currentColor"
                viewBox="0 0 20 16"
                className="ml-2 group-hover:translate-x-[5px] transition-transform duration-150"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.138 8.84H.84a.839.839 0 1 1 0-1.679h16.354L13.508 3.42a.839.839 0 1 1 1.213-1.16l5.044 5.14a.84.84 0 0 1-.013 1.173l-5.044 5.182a.839.839 0 1 1-1.187-1.186l3.617-3.729Z"
                ></path>
              </svg>
            </div>
            <div className="group p-6 border flex flex-col text-left justify-evenly rounded-3xl cursor-pointer bg-gradient-to-t from-[#e7e1f7] to-[#c3b2ec] hover:shadow-lg transition-transform transform hover:translate-y-[-10px] aspect-square">
              <img
                alt="Commerce-as-a-Service Icon"
                loading="lazy"
                width="48"
                height="48"
                decoding="async"
                data-nimg="1"
                src="https://storage.googleapis.com/s.mkswft.com/RmlsZTpjMjY2ZWNmNi0wM2Q3LTQ1MGMtYjNhYi0wNDQ5ZmZhOGEwMDQ=/icon-commerce-as-service.svg"
                className="text-transparent"
              />
              <p className="text-xl font-semibold text-gray-700">
                Commerce-as-a-Service
              </p>
              <svg
                height="16"
                width="20"
                fill="currentColor"
                viewBox="0 0 20 16"
                className="ml-2 group-hover:translate-x-[5px] transition-transform duration-150"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.138 8.84H.84a.839.839 0 1 1 0-1.679h16.354L13.508 3.42a.839.839 0 1 1 1.213-1.16l5.044 5.14a.84.84 0 0 1-.013 1.173l-5.044 5.182a.839.839 0 1 1-1.187-1.186l3.617-3.729Z"
                ></path>
              </svg>
            </div>
          </div> */}


          <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-5 lg:gap-20 mt-7">
            <Link to="/products/sales/inficonnect">
              <div className="group p-0.5 hover:bg-gradient-to-tr hover:from-pink-300 hover:to-blue-300 rounded-3xl cursor-pointer  aspect-square">
                <div className="bg-blue-100 w-[100%] h-[100%] rounded-3xl flex flex-col justify-evenly align-middle items-center px-5">
                  <img
                    alt="inficonnect Icon"
                    src="/inficonnectLogoPrevious.png"
                    className="h-6 md:h-32 object-contain"
                  />
                  <p className="text-lg md:text-xl font-semibold text-gray-700">
                    infiConnect
                  </p>
                </div>
              </div>
            </Link>
            <Link to="/products/sales/zeroclick">
              <div className="group p-0.5 hover:bg-gradient-to-tr hover:from-pink-300 hover:to-blue-300 rounded-3xl cursor-pointer  aspect-square">
                <div className="bg-green-100 w-[100%] h-[100%] rounded-3xl flex flex-col justify-evenly align-middle items-center px-5">

                  <img
                    alt="inficonnect Icon"
                    src="/zeroclickLogo.svg"
                    className="h-6 md:h-32 object-contain"
                  />
                  <p className="text-lg md:text-xl font-semibold text-gray-700">
                    0Click
                  </p>
                </div>
              </div>
            </Link>
            <Link to="/products/sales/expressbazaar">
              <div className="group p-0.5 hover:bg-gradient-to-tr hover:from-pink-300 hover:to-blue-300 rounded-3xl cursor-pointer  aspect-square">
                <div className="bg-red-100 w-[100%] h-[100%] rounded-3xl flex flex-col justify-evenly align-middle items-center px-5">
                  <img
                    alt="ExpressBazaar Icon"
                    src="/expressbazaarLogo.png"
                    className="h-6 md:h-32 object-contain"
                  />
                  <p className="text-lg md:text-xl font-semibold text-gray-700">
                    xpressbazaar
                  </p>
                </div>
              </div>
            </Link>
            <Link to="/products/sales/qwikco">
              <div className="group p-0.5 hover:bg-gradient-to-tr hover:from-pink-300 hover:to-blue-300 rounded-3xl cursor-pointer  aspect-square">
                <div className="bg-amber-100 w-[100%] h-[100%] rounded-3xl flex flex-col justify-evenly align-middle items-center px-5">
                  <img
                    alt="QwikcoLogo Icon"
                    src="/qwikcoLogo.png"
                    className="h-6 md:h-32 object-contain"
                  />
                  <p className="text-lg md:text-xl font-semibold text-gray-700">
                    Qwikco
                  </p>
                </div>
              </div>
            </Link>
          </div>
          {/* <div className="flex items-center justify-center mt-8">
            <Link to="/products">
              <button className="bg-[#7453E0] text-white text-sm md:text-base  font-semibold px-4 py-2 rounded-sm flex items-center justify-center gap-2">
                Explore All Products
                <MdOutlineChevronRight />
              </button>
            </Link>
          </div> */}
          <CustomButton to="/products" label="Explore All Products" />
        </div>
      </section>

      {/* Rotated Border Animation Div */}
      <div class="flex lg:h-[90vh] w-full flex-col items-center justify-center bg-[#f9edd7] px-5 py-14 md:px-8 md:py-36">
        <div className='w-full relative md:w-[80%] lg:w-[70%] xl:w-[80%]  max-w-screen-md h-40 md:h-64 lg:h-96 rounded-[75px] md:rounded-[500px]'>
          <div class="card-wrapper overflow-hidden  absolute w-full h-full left-0 rounded-full  ">
          </div>
          <div class=" h-full w-full top-0 bottom-0  absolute z-10 ">
            <div class="card-content  flex items-center justify-center text-xs rounded-[75px] md:rounded-[500px]" />
            <div className="text-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <p className="text-red-400 text-xs lg:text-base mb-3">Manage</p>
              <p className="text-xs md:text-base lg:text-2xl mb-3  lg:font-semibold">From shopping carts to content customers</p>
              <h1 className="text-sm md:text-xl lg:text-4xl font-medium md:font-semibold md:font-bold">your entire order cycle, automated</h1>
            </div>
            <div className="absolute top-[-30px] left-[20%] lg:top-[-65px] lg:left-[20%] flex flex-col items-center justify-center">
              <p className="text-sm md:text-base font-semibold mb-2 text-center hidden lg:block">
                Online Order Placed
              </p>
              <div className="animate-scale-image-1 bg-[#ffd8ce]  rounded-full w-10 h-10 md:w-16 md:h-16 lg:w-20 lg:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="/shoes.png"
                  alt="Seller App Icon"
                  className=""
                />
              </div>
            </div>
            <div className="absolute top-[-30px] left-[62%] lg:top-[-65px] lg:left-[65%] flex flex-col items-center justify-center">
              <p className="text-sm md:text-base font-semibold mb-2 text-center hidden lg:block">
                Payment Received
              </p>
              <div className="animate-scale-image-2 bg-[#d8f6e6] rounded-full w-10 h-10 md:w-16 md:h-16 lg:w-20 lg:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="/creditCard.png"
                  alt="Seller App Icon"
                  className=""
                />
              </div>
            </div>
            <div className="absolute top-[42%] -left-[4%] lg:top-[42%] lg:-left-[17%] flex items-center">
              <p className="text-sm md:text-base font-semibold mr-2 text-center hidden lg:block">
                Happy <br /> Customer +1
              </p>
              <div className="animate-scale-image-6 bg-[#d8f6e6] rounded-full w-10 h-10 md:w-16 md:h-16 lg:w-20 lg:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="/heart.png"
                  alt="Analytics"
                  className="object-contain w-full h-full"
                />
              </div>
            </div>
            <div className="absolute top-[42%] -right-[5%] lg:top-[42%] lg:-right-[20%] flex items-center">
              <div className="animate-scale-image-3 bg-[#ffdad0] rounded-full w-10 h-10 md:w-16 md:h-16 lg:w-20 lg:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="/inventoryCheck.png"
                  alt="Seller Apps"
                  className="object-contain w-full h-full"
                />
              </div>
              <p className="text-sm md:text-base font-semibold ml-2 text-center hidden lg:block">
                Inventory levels <br /> updated
              </p>
            </div>
            <div className="absolute bottom-[-20px] left-[20%] lg:bottom-[-85px] lg:left-[20%] flex flex-col items-center justify-center">
              <div className="animate-scale-image-5 bg-white rounded-full w-10 h-10 md:w-16 md:h-16 lg:w-20 lg:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="deliverBox.png"
                  alt="Shipping Icon"
                  className="object-contain w-full h-full"
                />
              </div>
              <p className="text-xs md:text-sm font-semibold mt-2 text-center hidden lg:block">
                Product Devilered to <br /> the customer
              </p>
            </div>
            <div className="absolute bottom-[-20px] left-[65%] lg:-bottom-[85px] lg:left-[65%] flex flex-col items-center justify-center">
              <div className="animate-scale-image-4 bg-[#f2f2f2] rounded-full w-10 h-10 md:w-16 md:h-16 lg:w-20 lg:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="/shipped.png"
                  alt="Shipping Icon"
                  className="object-contain w-full h-full"
                />
              </div>
              <p className="text-xs md:text-sm font-semibold mt-2 text-center hidden lg:block">
                Products shipped <br /> & tracked
              </p>
            </div>
          </div>
        </div>
      </div>


      {/* Without Rotated Border Animation Div */}
      {/* <section className="">
        <div className="flex flex-col items-center justify-center bg-[#f9edd7] px-4 py-14 md:px-8 md:py-36">
          <div className="relative w-full md:w-[80%] max-w-screen-md px-4 h-40 md:h-96">
            <div className="w-[100%] h-full border-[1px] md:border-[2px] border-black rounded-[75px] md:rounded-[500px] mx-auto" />
            <div className="text-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <p className="text-red-400 text-xs md:text-base mb-3">Manage</p>
              <p className="text-xs md:text-2xl mb-3  md:font-semibold">From shopping carts to content customers</p>
              <h1 className="text-sm md:text-4xl font-medium md:font-semibold md:font-bold">your entire order cycle, automated</h1>
            </div>
            <div className="absolute top-[-30px] left-[25%] md:top-[-65px] md:left-[25%] flex flex-col items-center justify-center">
              <p className="text-sm md:text-base font-semibold mb-2 text-center hidden md:block">
                Online Order Placed
              </p>
              <div className="bg-[#ffd8ce] rounded-full w-10 h-10 md:w-20 md:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="/shoes.png"
                  alt="Seller App Icon"
                  className=""
                />
              </div>
            </div>
            <div className="absolute top-[-30px] left-[62%] md:top-[-65px] md:left-[65%] flex flex-col items-center justify-center">
              <p className="text-sm md:text-base font-semibold mb-2 text-center hidden md:block">
                Payment Received
              </p>
              <div className="bg-[#d8f6e6] rounded-full w-10 h-10 md:w-20 md:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="/creditCard.png"
                  alt="Seller App Icon"
                  className=""
                />
              </div>
            </div>
            <div className="absolute top-[45%] -left-[0%] md:top-[45%] md:-left-[15%] flex items-center">
              <p className="text-sm md:text-base font-semibold mr-2 text-center hidden md:block">
                Happy <br /> Customer +1
              </p>
              <div className="bg-[#d8f6e6] rounded-full w-10 h-10 md:w-20 md:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="/heart.png"
                  alt="Analytics"
                  className="object-contain w-full h-full"
                />
              </div>
            </div>
            <div className="absolute top-[45%] -right-[0%] md:top-[45%] md:-right-[18%] flex items-center">
              <div className="bg-[#ffdad0] rounded-full w-10 h-10 md:w-20 md:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="/inventoryCheck.png"
                  alt="Seller Apps"
                  className="object-contain w-full h-full"
                />
              </div>
              <p className="text-sm md:text-base font-semibold ml-2 text-center hidden md:block">
                Inventory levels <br /> updated
              </p>
            </div>
            <div className="absolute bottom-[-20px] left-[25%] md:bottom-[-85px] md:left-[25%] flex flex-col items-center justify-center">
              <div className="bg-white rounded-full w-10 h-10 md:w-20 md:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="deliverBox.png"
                  alt="Shipping Icon"
                  className="object-contain w-full h-full"
                />
              </div>
              <p className="text-xs md:text-sm font-semibold mt-2 text-center hidden md:block">
                Product Devilered to <br /> the customer
              </p>
            </div>
            <div className="absolute bottom-[-20px] left-[65%] md:-bottom-[85px] md:left-[65%] flex flex-col items-center justify-center">
              <div className="bg-[#f2f2f2] rounded-full w-10 h-10 md:w-20 md:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="/shipped.png"
                  alt="Shipping Icon"
                  className="object-contain w-full h-full"
                />
              </div>
              <p className="text-xs md:text-sm font-semibold mt-2 text-center hidden md:block">
                Products shipped <br /> & tracked
              </p>
            </div>
          </div>
        </div>
      </section> */}


      {/* <section className="">
        <div className="flex flex-col items-center justify-center bg-[#f9edd7] px-4 py-14 md:px-8 md:py-36">
          <div className="relative w-full md:w-[80%] max-w-screen-md px-4 h-40 md:h-96">
            <div className="w-[100%] h-full border-[1px] md:border-[2px] border-black rounded-[75px] md:rounded-[500px] mx-auto" />
            <div className="text-center absolute left-1/2 -top-[75px] transform -translate-x-1/2 ">
              <p className="text-lg ">Seller apps</p>
            </div>
            <div className="text-center absolute left-1/2 bottom-[75px] transform -translate-x-1/2 ">
              <p className="text-lg ">Shipping</p>
            </div>
            <div className="text-center absolute -left-[120px] top-1/2 transform -translate-y-1/2">
              <p className="text-lg ">Analytics</p>
            </div>
            <div className="text-center absolute -right-[120px] top-1/2 transform -translate-y-1/2">
              <p className="text-lg ">CRM</p>
            </div>
            <div className="text-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <p className="text-red-400 text-xs md:text-base mb-3">Manage</p>
              <p className="text-xs md:text-2xl mb-3  md:font-semibold">From shopping carts to content customers</p>
              <h1 className="text-sm md:text-4xl font-medium md:font-semibold md:font-bold">your entire order cycle, automated</h1>
            </div>

            <div className="absolute top-[-15px] left-[15%] flex items-center justify-center bg-white rounded-full w-8 h-8 md:w-16 md:h-16 p-2">
              <img
                src="/sellarApp1.png"
                alt="Seller App Icon"
                className="object-cover"
              />
            </div>
            <div className="absolute top-[-30px] left-[25%] md:top-[-65px] md:left-[25%] flex flex-col items-center justify-center">
              <p className="text-sm md:text-base font-semibold mb-2 text-center hidden md:block">
                Online Order Placed
              </p>
              <div className="bg-[#ffd8ce] rounded-full w-10 h-10 md:w-20 md:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="/shoes.png"
                  alt="Seller App Icon"
                  className=""
                />
              </div>
            </div>

            <div className="absolute top-[-30px] left-[62%] md:top-[-65px] md:left-[65%] flex flex-col items-center justify-center">
              <p className="text-sm md:text-base font-semibold mb-2 text-center hidden md:block">
                Payment Received
              </p>
              <div className="bg-[#d8f6e6] rounded-full w-10 h-10 md:w-20 md:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="/creditCard.png"
                  alt="Seller App Icon"
                  className=""
                />
              </div>
            </div>

            <div className="absolute top-[-15px] left-[75%] flex items-center justify-center bg-white rounded-full w-8 h-8 md:w-16 md:h-16 p-2">
              <img
                src="/sellarApp4.png"
                alt="Seller App Icon"
                className="object-cover"
              />
            </div>

            <div className="absolute top-[45%] -left-[0%] md:top-[45%] md:-left-[15%] flex items-center">
              <p className="text-sm md:text-base font-semibold mr-2 text-center hidden md:block">
                Happy <br /> Customer +1
              </p>
              <div className="bg-[#d8f6e6] rounded-full w-10 h-10 md:w-20 md:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="/heart.png"
                  alt="Analytics"
                  className="object-contain w-full h-full"
                />
              </div>
            </div>

            <div className="absolute top-[65%] left-[0%] flex items-center justify-center bg-white rounded-full w-8 h-8 md:w-16 md:h-16 p-2">
              <img src="/analytics2.png" alt="Analytics" className="object-cover" />
            </div>

            <div className="absolute top-[45%] -right-[0%] md:top-[45%] md:-right-[18%] flex items-center">
              <div className="bg-[#ffdad0] rounded-full w-10 h-10 md:w-20 md:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="/inventoryCheck.png"
                  alt="Seller Apps"
                  className="object-contain w-full h-full"
                />
              </div>

              <p className="text-sm md:text-base font-semibold ml-2 text-center hidden md:block">
                Inventory levels <br /> updated
              </p>
            </div>


            <div className="absolute bottom-[-20px] left-[25%] md:bottom-[-85px] md:left-[25%] flex flex-col items-center justify-center">
              <div className="bg-white rounded-full w-10 h-10 md:w-20 md:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="deliverBox.png"
                  alt="Shipping Icon"
                  className="object-contain w-full h-full"
                />
              </div>

              <p className="text-xs md:text-sm font-semibold mt-2 text-center hidden md:block">
                Product Devilered to <br /> the customer
              </p>
            </div>

            <div className="absolute bottom-[-25px] left-[45%] flex items-center justify-center bg-white rounded-full w-8 h-8 md:w-16 md:h-16 p-2">
              <img
                src="shipping2.png"
                alt="Shipping Icon"
                className="object-cover"
              />
            </div>
            <div className="absolute bottom-[-20px] left-[65%] md:-bottom-[85px] md:left-[65%] flex flex-col items-center justify-center">
              <div className="bg-[#f2f2f2] rounded-full w-10 h-10 md:w-20 md:h-20 flex items-center justify-center p-2 shadow-md">
                <img
                  src="/shipped.png"
                  alt="Shipping Icon"
                  className="object-contain w-full h-full"
                />
              </div>

              <p className="text-xs md:text-sm font-semibold mt-2 text-center hidden md:block">
                Products shipped <br /> & tracked
              </p>
            </div>



          </div>
        </div>
      </section> */}


      <section className="">
        <div className="flex flex-col max-w-screen-xl xl:max-w-screen-2xl mx-auto lg:flex-row items-center justify-between lg:gap-16 gap-8 py-16 px-4 bg-white text-black">
          {/* Left Section */}
          <div className="lg:w-1/2">
            <div className="flex items-center gap-2 mb-4">
              {/* Logo */}
              <Link className="flex items-center" to="/">
                <img src="/logo.jpg" alt="Logo" className="h-8 md:h-10" />
              </Link>
            </div>
            <p className=" text-xl mb-6">All in -one suite</p>
            <h2 className="text-2xl font-semibold md:text-3xl md:font-bold mb-4">Fixall Solution</h2>
            <p className=" text-lg mb-8">
              The operating system for business
            </p>
            <p className=" text-lg mb-6">
              Run your entire business on Fixall with our unified cloud software,
              designed to help you break down silos between departments and
              increase organizational efficiency.
            </p>
            {/* <div className="mt-8">
              <Link to="/products/sales/inficonnect">
                <button className="bg-red-600 text-white text-sm md:text-base font-semibold px-4 py-2 rounded-sm flex items-center justify-center gap-2">
                  Try Fixall Solution
                  <MdOutlineChevronRight />
                </button>
              </Link>
            </div> */}
            <CustomButton to="/products/sales/inficonnect" label="Try Fixall Solution" position="left" color="#E53935" />

          </div>

          {/* Divider */}
          <div className="hidden lg:block h-[80%] w-[2px] bg-gray-300"></div>

          {/* Right Section */}
          {/* <div className="lg:w-1/2 text-center lg:text-left"> */}
          {/* <div>
              <div className="w-7 h-7 p-1.5 flex justify-center align-middle items-center rounded-full bg-black mb-8">
                <img
                  src="doublequote.png"
                  alt="Profile"
                  className=" object-cover"
                />
              </div>

              <blockquote className="text-gray-800 text-2xl font-semibold mb-6">
                &ldquo;You can be a startup, mid-sized company, or an enterprise—Fixall
                One is a boon for all.&rdquo;
              </blockquote>
              <div className="flex items-center justify-center lg:justify-start gap-4">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqcVXIgWCvTbb55lDj91N_g2rd0F3rma21CA&s"
                  alt="Profile"
                  className="w-12 h-12 rounded-full object-cover"
                />
                <div>
                  <p className="font-semibold text-xl">Shubham Sharma</p>
                  <p className=" text-lg">
                    CEO, 5paisa.com (an IIFL subsidiary)
                  </p>
                </div>
              </div>
            </div> */}

          <div className="overflow-hidden h-64 w-full lg:w-1/2 relative">
            <div
              ref={containerRef}
              className="flex flex-col"
              style={{
                transform: `translateY(-${(currentIndex % items.length) * 18}%)`,
              }}
            >
              {clonedItems.map((item, index) => (
                <div
                  key={index}
                  className="h-64 flex flex-col justify-center  px-6"
                >
                  <div className="w-5 h-5 md:w-7 md:h-7 p-1.5 flex justify-center align-middle items-center rounded-full bg-black mb-4 md:mb-8">
                    <img
                      src="doublequote.png"
                      alt="Double Quote"
                      className="object-cover"
                    />
                  </div>
                  <blockquote className="text-gray-800 text-lg md:text-2xl font-semibold mb-6 text-left">
                    &ldquo;{item.quote}&rdquo;
                  </blockquote>
                  <div className="flex items-center gap-4">
                    <img
                      src={item.img}
                      alt={item.name}
                      className="w-7 h-7 md:w-12 md:h-12 rounded-full object-cover"
                    />
                    <div>
                      <p className="font-semibold text-base md:text-xl">{item.name}</p>
                      <p className="text-sm md:text-lg">{item.title}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

        </div>
        {/* </div> */}
      </section>

      <section class="bg-[#00A69C] border text-white relative">
        <div class="items-center max-w-screen-xl xl:max-w-screen-2xl px-4 py-8 mx-auto lg:grid lg:grid-cols-5 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
          <div class="col-span-2 mb-8">
            <p class="text-lg font-medium text-white ">
              Trusted Worldwide
            </p>
            <h2 class="mt-3 mb-4 md:tracking-wider text-2xl font-medium md:font-bold lg:font-extrabold tracking-tight text-white md:text-3xl ">
              Trusted by over 600 million users and 10,000 teams
            </h2>
            <p class="font-light text-white sm:text-xl border-y border-gray-200 py-5">
              Our rigorous security and compliance standards are at the heart of
              all we do. We work tirelessly to protect you and your customers.
            </p>
            <div class="pt-6 mt-6 space-y-4  ">
              <div>
                <a
                  class="inline-flex items-center text-base font-medium text-white  "
                  href="#"
                >
                  Explore Legality Guide
                  <svg
                    class="w-5 h-5 ml-1"
                    fill="currentColor"
                    viewbox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
              <div>
                <a
                  class="inline-flex items-center text-base font-medium text-white  "
                  href="#"
                >
                  Visit the Trust Center
                  <svg
                    class="w-5 h-5 ml-1"
                    fill="currentColor"
                    viewbox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="h-[90%] w-[1px] col-span-1 grid-cols-1 bg-white" />
          <div class=" col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
            <div className="flex flex-col gap-5">
              <img src="/uptime.png" className="h-12 w-12 object-cover " />
              <h3 class="mb-2 text-xl md:text-2xl font-semibold md:font-bold ">
                99.99% uptime
              </h3>
              <p class="text-white ">
                For Fixall, with zero maintenance downtime
              </p>
            </div>
            <div className="flex flex-col gap-5">
              <img src="/user.png" className="h-12 w-12 object-cover " />

              <h3 class="mb-2 text-xl md:text-2xl font-semibold md:font-bold ">
                600M+ Users
              </h3>
              <p class="text-white ">
                Trusted by over 600 milion users around the world
              </p>
            </div>
            <div className="flex flex-col gap-5">
              <img src="/countries.png" className="h-12 w-12 object-cover " />

              <h3 class="mb-2 text-xl md:text-2xl font-semibold md:font-bold  ">
                100+ countries
              </h3>
              <p class="text-white ">
                Have used Fixall to create functional websites
              </p>
            </div>
            <div className="flex flex-col gap-5">
              <img src="/transaction.png" className="h-12 w-12 object-cover " />

              <h3 class="mb-2 text-xl md:text-2xl font-semibold md:font-bold  ">
                5+ Million
              </h3>
              <p class="text-white ">
                Transactions per day
              </p>
            </div>
          </div>
        </div>
        <img src="/monumentsGroup.png" className="absolute -bottom-4 right-0" />
      </section>

      <section className="relative md:mb-[800px] lg:mb-28">
        <div className="lg:pb-96">
          <img src="/employees.png" alt="Employees" className="h-96 w-full object-cover" />
        </div>
        <div className="md:absolute bg-[#F7F5FF] text-center md:left-1/2 md:transform md:-translate-x-1/2 w-full max-w-screen-xl xl:max-w-screen-2xl p-8 lg:-bottom-24">
          <h1 className="font-semibold text-2xl md:3xl lg:text-4xl">The core values and <br /> principles that drive us</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 text-center mt-10">
            <div className="bg-white flex flex-col gap-2 md:gap-7 justify-center align-middle items-center p-5 md:p-10">
              <div className="h-14 w-14 md:h-24 md:w-24">
                <img src="/vision.png" className="h-[100%] w-[100%] object-cover"></img>
              </div>
              <h3 className="text-lg md:text-xl font-semibold">Vision</h3>
              <p className="text-sm md:text-base">25+ years of running a profitable organization gives us a good sense of challenges that a growing business faces. We take pride in running a sustainable business that’s powered by you, our customer.</p>
            </div>
            <div className="bg-white flex flex-col gap-2 md:gap-7 justify-center align-middle items-center p-5 md:p-10">
              <div className="h-14 w-14 md:h-24 md:w-24">
                <img src="/mission.png" className="h-[100%] w-[100%] object-cover"></img>
              </div>
              <h3 className="text-lg md:text-xl font-semibold">Mission</h3>
              <p className="text-sm md:text-base">Software is our craft and we back it up with our relentless investments in R&D. So much so that we prefer to own the entire technology stack, including running our data centers globally.</p>
            </div>
            <div className="bg-white flex flex-col gap-2 md:gap-7 justify-center align-middle items-center p-5 md:p-10">
              <div className="h-14 w-14 md:h-24 md:w-24">
                <img src="/coreValues.png" className="h-[100%] w-[100%] object-cover"></img>
              </div>
              <h3 className="text-lg md:text-xl font-semibold">Core Values</h3>
              <p className="text-sm md:text-base">In all these years, it's our customers' trust and goodwill that has helped us establish a strong position in the market. No matter the size of your business, we're here to help you grow.</p>
            </div>
          </div>
          {/* <div className="flex items-center justify-center mt-8">
            <Link to="/ourstory">
              <button className="bg-[#7453E0] text-white font-semibold md:text-base text-sm px-4 py-2 rounded-sm flex items-center justify-center gap-2">
                Read Our Story
                <MdOutlineChevronRight />
              </button>
            </Link>
          </div> */}
          <CustomButton to="/ourstory" label="Read Our Story"/>


        </div>
      </section>

    </Layout>
  );
};

export default Home;