import React, { useState } from 'react';
import Layout from '../components/layout';

function Customers() {
    const logos = [
        { src: '/brands/Frame 1.png', alt: 'Logo 1', bgColor: 'bg-pink-100' },
        { src: '/brands/Frame 2.png', alt: 'Logo 2', bgColor: 'bg-green-100' },
        { src: '/brands/Frame 3.png', alt: 'Logo 3', bgColor: 'bg-blue-100' },
        { src: '/brands/Frame 4.png', alt: 'Logo 4', bgColor: 'bg-yellow-100' },
        { src: '/brands/Frame 5.png', alt: 'Logo 5', bgColor: 'bg-purple-100' },
        { src: '/brands/Frame 6.png', alt: 'Logo 6', bgColor: 'bg-red-100' },
        { src: '/brands/Frame 7.png', alt: 'Logo 7', bgColor: 'bg-indigo-100' },
        { src: '/brands/Frame 8.png', alt: 'Logo 8', bgColor: 'bg-teal-100' },
        { src: '/brands/Frame 9.png', alt: 'Logo 9', bgColor: 'bg-orange-100' },
        { src: '/brands/Frame 10.png', alt: 'Logo 10', bgColor: 'bg-cyan-100' },

    ];

    const [showFilters, setShowFilters] = useState(false);

    const filters = {
        Industry: ['Accounting', 'Advertising Agency', 'Agriculture', 'Automobile'],
        Product: ['Zoho Analytics', 'Zoho Assist', 'Zoho Backstage', 'Bigin'],
        Country: ['Argentina', 'Australia', 'Canada', 'France'],
    };

    const cards = [
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
        { title: 'SpareX', description: 'Zoho Analytics supercharges our sales!', image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg' },
        { title: 'Critical Insight', description: 'Zoho Assist simplifies troubleshooting.', image: 'https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?cs=srgb&dl=pexels-olly-874158.jpg&fm=jpg' },
       
    ];

    return (
        <Layout>
            <div className="min-h-screen w-full py-16">
                <div className=" text-center w-full">
                    <div className="text-center pb-10">
                        <h1 className="text-3xl md:text-[56px] font-bold mb-4 px-5 md:leading-[60px]">
                            <span>Trusted by over</span>
                            <span className="bg-gradient-to-r from-[#2627fa] to-[#6d26d0] text-transparent bg-clip-text"> 100 Million Users </span>
                            <span>globally</span>
                        </h1>
                        <p className="text-[15px]">The world’s leading companies trust Zoho to run their different business operations.</p>
                    </div>
                    <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 mt-4">
                        {logos.map((logo, index) => (
                            <div key={index} className={`flex items-center justify-center aspect-square ${logo.bgColor}`}>
                                <img src={logo.src} alt={logo.alt} className="w-10 md:w-16 lg:w-32" />
                            </div>
                        ))}
                    </div>

                    <div className="relative h-screen max-w-screen-xl xl:max-w-screen-2xl px-4 mx-auto flex overflow-hidden mt-4 pt-4">
                        {/* Sidebar as popup overlay for filters */}
                        <div
                            className={`fixed inset-0 bg-white p-4 w-full h-full z-50 md:z-0 lg:static lg:w-64 ${showFilters ? 'block' : 'hidden'} lg:flex-shrink-0 lg:block overflow-y-auto`}
                        >
                            <button
                                className="lg:hidden text-gray-500 font-semibold mb-4"
                                onClick={() => setShowFilters(false)}
                            >
                                Close
                            </button>
                            <div className="space-y-6">
                                {Object.keys(filters).map((category) => (
                                    <div key={category} className="border-b pb-4">
                                        <h3 className="font-semibold text-lg">{category}</h3>
                                        <ul className="space-y-2 pt-2">
                                            {filters[category].map((item) => (
                                                <li key={item} className="flex items-center space-x-2">
                                                    <input type="checkbox" id={item} />
                                                    <label htmlFor={item} className="text-sm">{item}</label>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Content area with cards */}
                        <div className="flex-1 overflow-y-auto p-4">
                            {/* Filter button for mobile view */}
                            <button
                                className="lg:hidden mb-4 px-4 py-2 text-slate-900 hover:bg-slate-900 hover:text-white rounded-md border-[1px] border-slate-900"
                                onClick={() => setShowFilters(!showFilters)}
                            >
                                Filter By
                            </button>

                            {/* Cards section */}
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                {cards.map((card, index) => (
                                    <div key={index} className="bg-white shadow-md rounded-lg overflow-hidden">
                                        <img src={card.image} alt={card.title} className="w-full h-40 object-cover" />
                                        <div className="p-4">
                                            <h4 className="text-xl font-semibold">{card.title}</h4>
                                            <p className="text-sm mt-2 text-gray-600">{card.description}</p>
                                            <div className="mt-4">
                                                <a href="#" className="text-slate-500 font-semibold">Read more</a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Customers;
