import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineChevronRight } from "react-icons/md";
import CustomButton from "../pages/custombutton";


const HeroVideoSection = () => {
  return (
    <>
      <div className="relative h-min-screen overflow-hidden">
        {/* Video Background */}
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          loop
          muted
        >
          <source src="/hero1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Content Overlay */}
        <div className="relative flex items-center justify-center h-full bg-black bg-opacity-20">
          <div className="max-w-screen-xl xl:max-w-screen-2xl mx-auto flex flex-col px-4 lg:flex-row items-center justify-between py-20">
            {/* Left Section */}
            <div className="lg:w-1/2 text-center lg:text-left">
              <h1 className="text-3xl font-semibold lg:text-5xl md:font-bold text-white leading-tight">
                Your life's work, <br />
                powered by our life's work
              </h1>
              <p className="mt-4 text-white text-lg">
                A unique and powerful software suite to transform the way you
                work. Designed for businesses of all sizes, built by a company
                that <span className="underline">values your privacy.</span>
              </p>
              {/* <Link to="/products" >
                <button className="mt-6 px-6 py-3 bg-red-600 text-white text-sm md:text-base font-normal md:font-medium rounded shadow hover:bg-red-700">
                  Access Your Apps
                </button>
              </Link> */}
              <CustomButton to="/products" label="Access Your Apps" position="left" showArrow={false} color="#E53935"/>

            </div>

            {/* Right Section */}
            <div className="lg:w-1/2 hidden  mt-16 lg:mt-0 lg:flex justify-center md:justify-end">
              <div className="bg-white bg-opacity-70 lg:w-1.5/3 shadow-lg rounded-md p-6">
                <h2 className="text-xl font-semibold mb-4">Featured Apps</h2>
                <ul className="space-y-4">
                  {[
                    {
                      name: "infiConnect",
                      desc: "Comprehensive CRM platform for customer-facing teams.",
                      icon: "🔗",
                      url: "/products/sales/inficonnect"
                    },
                    {
                      name: "0Click",
                      desc: "Secure email service for teams of all sizes.",
                      icon: "📧",
                      url: "/products/sales/0click"
                    },
                    {
                      name: "Xpressbazaar",
                      desc: "Powerful accounting platform for growing businesses.",
                      icon: "📘",
                      url: "/products/sales/xpressbazaar"
                    },
                    {
                      name: "Qwikco",
                      desc: "Organize, automate, and simplify your HR processes.",
                      icon: "👥",
                      url: "/products/sales/qwikco"
                    },
                    // {
                    //   name: "Payroll",
                    //   desc: "Effortless payroll processing software for businesses.",
                    //   icon: "💰",
                    // },
                  ].map((app, index) => (
                    <Link to={`${app?.url}`} key={index} className="flex items-center hover:border hover:bg-zinc-100 rounded-md p-2">
                      <div className="w-10 h-10 flex items-center justify-center bg-gray-200 rounded-full text-lg">
                        {app.icon}
                      </div>
                      <div className="ml-4">
                        <h3 className="font-medium text-gray-900">{app.name}</h3>
                        <p className="text-sm text-gray-600">{app.desc}</p>
                      </div>
                    </Link>
                  ))}
                </ul>
                {/* <Link to="/products" >
                  <button className="mt-6 px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600">
                    Explore All Products
                  </button>
                </Link> */}
                {/* <Link to="/products">
                  <button className="bg-[#7453E0] mt-6 text-white text-sm md:text-base  font-semibold px-4 py-2 rounded-sm flex items-center justify-center gap-2">
                    Explore All Products
                    <MdOutlineChevronRight />
                  </button>
                </Link> */}
                <CustomButton to="/products" label="Explore All Products" position="left" />

              </div>
              {/* <img src="/fixallbg.png" /> */}

              {/* <div className="flex justify-center items-center ">
                <img
                  src="/fixallbg.png"
                  alt="Moving Image"
                  className="w-full sm:w-auto animate-updown"
                />
                <style>
                  {`
          @keyframes updown {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-15px);
            }
            100% {
              transform: translateY(0);
            }
          }
          .animate-updown {
            animation: updown 2s ease-in-out infinite;
          }
        `}
                </style>
              </div> */}


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroVideoSection;