import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { Link } from "react-router-dom";
import { MdOutlineChevronRight } from "react-icons/md";
import { FaCheck } from "react-icons/fa";

const Inficonnect = () => {
  const imagesSlider1 = ["/slider1.png", "/slider2.png", "/slider3.png"];

  const imagesSlider2 = ["/slider2.png", "/slider3.png", "/slider1.png"];
  const logos = [
    { id: 1, href: "#", imgSrc: "/brands/Frame 1.png", alt: "Microsoft" },
    { id: 2, href: "#", imgSrc: "/brands/Frame 2.png", alt: "microsoft" },
    { id: 3, href: "#", imgSrc: "/brands/Frame 3.png", alt: "microsoft" },
    { id: 4, href: "#", imgSrc: "/brands/Frame 4.png", alt: "microsoft" },
    { id: 5, href: "#", imgSrc: "/brands/Frame 5.png", alt: "microsoft" },
    { id: 6, href: "#", imgSrc: "/brands/Frame 6.png", alt: "microsoft" },
    { id: 7, href: "#", imgSrc: "/brands/Frame 7.png", alt: "Microsoft" },
    { id: 8, href: "#", imgSrc: "/brands/Frame 8.png", alt: "Microsoft" },
    { id: 9, href: "#", imgSrc: "/brands/Frame 9.png", alt: "Microsoft" },
    { id: 10, href: "#", imgSrc: "/brands/Frame 10.png", alt: "Microsoft" },
  ];

  const [offset1, setOffset1] = useState(100);
  const [offset2, setOffset2] = useState(400);
  const [direction1, setDirection1] = useState("down");
  const [direction2, setDirection2] = useState("up");

  const [translateX, setTranslateX] = useState(0);

  // Width calculation for the entire slider (to accommodate both original and duplicated logos)
  const logoWidth = 200; // Adjust the logo width (in px)
  const totalWidth = logoWidth * logos.length * 2; // Multiply by 2 for the duplicate logos

  useEffect(() => {
    const interval = setInterval(() => {
      setTranslateX((prev) => prev - 1); // Adjust this value for speed (negative to scroll left)
    }, 10); // Time interval for smooth scrolling

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // When the first set of logos has fully scrolled off, reset to the start (without any gap)
    if (Math.abs(translateX) >= totalWidth / 2) {
      setTranslateX(0); // Reset position for a seamless loop
    }
  }, [translateX]);

  useEffect(() => {
    const interval = setInterval(() => {
      setOffset1((prevOffset) => {
        const nextOffset =
          direction1 === "down" ? prevOffset + 1 : prevOffset - 1;
        if (nextOffset > imagesSlider1.length * 200 - 90) setDirection1("up");
        if (nextOffset < 0) setDirection1("down");
        return nextOffset;
      });

      setOffset2((prevOffset) => {
        const nextOffset =
          direction2 === "down" ? prevOffset + 1 : prevOffset - 1;
        if (nextOffset > imagesSlider2.length * 200 - 90) setDirection2("up");
        if (nextOffset < 0) setDirection2("down");
        return nextOffset;
      });
    }, 10);

    return () => clearInterval(interval);
  }, [direction1, direction2, imagesSlider1.length, imagesSlider2.length]);
  return (
    <Layout>
      <div className=" min-h-screen flex flex-col items-center">
        <div class="bg-[#EDE8EE] w-full">
          <div className="grid max-w-screen-xl xl:max-w-screen-2xl px-4 py-8 mx-auto xl:h-[90vh] lg:gap-8 xl:gap-4 xl:py-0 lg:grid-cols-12 xl:pt-0 justify-center">
            <div class="mr-auto place-self-center lg:col-span-7 px-5 xl:pl-20 flex flex-col lg:items-start items-center justify-center ">
              <h1 class="max-w-2xl md:tracking-wide lg:mb-4 text-3xl font-semibold md:font-bold leading-none tracking-tight md:text-6xl xl:text-7xl ">
                Connect Your <br className="hidden lg:block" /> Clients
              </h1>
              <p class="max-w-lg my-6 text-sm font-normal lg:mb-8 md:text-lg lg:text-xl text-center lg:text-left">
                Create a delightful online journey by optimising your shipping
                process and everything surrounding it.
              </p>
              <Link to="/products/sales/inficonnect/signup">
                <button className="bg-[#7453E0] text-white text-sm md:text-base  font-semibold px-4 py-2 rounded-sm flex items-center justify-center gap-2">
                  Get Started
                  <MdOutlineChevronRight />
                </button>
              </Link>
            </div>

            {/* <div className="flex w-full h-[90vh]"> */}
            <div class="hidden lg:mt-0 lg:col-span-5 h-full lg:flex">
              {/* Slider 1 */}
              <div className="w-1/2 overflow-hidden relative">
                <div
                  className="absolute"
                  style={{
                    transform: `translateY(-${offset1}px)`,
                    transition: "transform 0.1s linear",
                  }}
                >
                  {imagesSlider1.map((img, index) => (
                    <img
                      key={index}
                      src={img}
                      alt={`Slider1-${index}`}
                      className="w-full h-auto object-cover"
                    />
                  ))}
                </div>
              </div>

              {/* Slider 2 */}
              <div className="w-1/2 overflow-hidden relative">
                <div
                  className="absolute"
                  style={{
                    transform: `translateY(-${offset2}px)`,
                    transition: "transform 0.5s linear",
                  }}
                >
                  {imagesSlider2.map((img, index) => (
                    <img
                      key={index}
                      src={img}
                      alt={`Slider2-${index}`}
                      className="w-full h-auto object-cover"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="bg-[#251230] text-white border-t border-[#251230] w-full h-auto">
          <div className="bg-[#EDE8EE] w-full">
            <div className="text-center capitalize py-4 lg:px-4 md:px-4 md:py-4 max-w-screen-xl xl:max-w-screen-2xl mx-auto">
              <h1 className="text-lg md:text-3xl font-semibold text-[#251230]">
                The Role of Retail Stores in Customer Engagement
              </h1>
            </div>
          </div>
          <div className="max-w-screen-xl xl:max-w-screen-2xl mx-auto flex flex-col-reverse lg:flex-row items-center h-auto">
            <div className="flex flex-col-reverse lg:flex-row justify-center items-center gap-8 px-4 py-10 w-full">
              {/* Left Content */}
              <div className="flex flex-col items-center lg:items-end text-center lg:text-left max-w-full lg:max-w-[35%] space-y-6">
                <div>
                  <h2 className="text-lg font-semibold">
                    Vital Role in Customer Engagement
                  </h2>
                  <p className="text-xs lg:text-sm">
                    Retail stores are essential in fostering critical customer
                    engagements that directly influence sales and brand loyalty.
                  </p>
                </div>
                <div>
                  <h2 className="text-lg font-semibold">
                    Learning about Products
                  </h2>
                  <p className="text-xs lg:text-sm">
                    Retail environments provide customers the opportunity to
                    learn about new products, offers, and promotions.
                  </p>
                </div>
              </div>

              {/* Center Image */}
              <div className="relative w-40 h-40 lg:w-64 lg:h-64 lg:max-w-[30%] rounded-full flex items-center justify-center">
                <img
                  src="/engagementBackground.png"
                  alt="Center Icon"
                  className="w-full h-full object-cover rotateCenter"
                />
              </div>

              {/* Right Content */}
              <div className="flex flex-col items-center lg:items-start text-center lg:text-left max-w-full lg:max-w-[35%] space-y-6">
                <div>
                  <h2 className="text-lg font-semibold">Primary Touchpoints</h2>
                  <p className="text-xs md:text-sm">
                    These stores serve as the main touchpoints where customers
                    can experience a brand's offerings firsthand.
                  </p>
                </div>
                <div>
                  <h2 className="text-lg font-semibold">
                    Customer Interaction
                  </h2>
                  <p className="text-xs md:text-sm">
                    Customers interact with staff in retail stores, enhancing
                    their overall experience with the brand.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <section className="relative w-full bg-[#EDE8EE]  h-[80vh]">
          <div className='max-w-screen-xl xl:max-w-screen-2xl mx-auto  flex flex-col-reverse md:flex-row items-center h-full'>
            <div className="w-full md:w-1/2 space-y-6 md:space-y-8 px-4 md:px-8 py-12 md:py-20">
              <h1 className="text-2xl md:text-4xl font-semibold text-[#251230] leading-tight">
                The Importance of <br /> Retail Stores in <br /> Customer Engagement
              </h1>
              <p className="text-gray-600 text-base md:text-xl">
                Retail Stores Play a vital role in fostering critical customer engagement.
              </p>

              <Link to="/products/sales/inficonnect/signup" >
                <button className=" text-[#F48FB1] font-semibold pt-5">
                  InfiConnect
                </button>
              </Link>
            </div>

            <div className="w-1/2 h-full  flex justify-center relative">
              <img
                src="/inficonnectBackground.jpg"
                alt="Product Catalog Illustration"
                className="w-full h-full "
              />
              <div class="hidden lg:mt-0 lg:col-span-5 h-full lg:flex">
                <div className="w-1/2 overflow-hidden relative">
                  <div
                    className="absolute"
                    style={{
                      transform: `translateY(-${offset1}px)`,
                      transition: "transform 0.1s linear",
                    }}
                  >
                    {imagesSlider1.map((img, index) => (
                      <img
                        key={index}
                        src={img}
                        alt={`Slider1-${index}`}
                        className="w-full h-auto object-cover"
                      />
                    ))}
                  </div>
                </div>

                <div className="w-1/2 overflow-hidden relative">
                  <div
                    className="absolute"
                    style={{
                      transform: `translateY(-${offset2}px)`,
                      transition: "transform 0.5s linear",
                    }}
                  >
                    {imagesSlider2.map((img, index) => (
                      <img
                        key={index}
                        src={img}
                        alt={`Slider2-${index}`}
                        className="w-full h-auto object-cover"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <div className="flex flex-col lg:flex-row items-center bg-[#EDE8EE] text-[#251230] py-10 lg:py-12 px-4 w-full h-full">
          {/* Left Container */}
          <div className="max-w-screen-xl xl:max-w-screen-2xl mx-auto flex flex-col-reverse md:flex-row items-center h-[100%] max-h-full gap-20">
            <div className="lg:w-[70%] items-center min-h-56 max-h-full">
              <div className="grid grid-cols-1 md:grid-cols-3 md:gap-8">
                {/* Card 1 */}
                <div className=" text-black p-5 pt-3 lg:pt-3 flex flex-col items-center shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-md">
                  <div className="mb-4">
                    {/* Replace with your image path */}
                    <img
                      src="/automation1.png"
                      alt="Challenges Icon"
                      className="w-14 h-14 md:w-24 md:h-24"
                    />
                  </div>
                  <h2 className="text-base md:text-lg font-medium md:font-semibold mb-2 text-center text-[#251230] border-[1px] border-[#6d567a] rounded-xl px-2 py-2 md:px-7 md:py-5 min-h-20 w-full lg:min-h-32 flex items-center justify-center">
                    Challenges of Non-Automated Communication
                  </h2>
                  <p className="text-sm text-center">
                    Without automation, personalized communication regarding new
                    product launches, special promotions, sales previews, brand
                    trivia, and limited stock alerts becomes cumbersome.
                  </p>
                </div>

                {/* Card 2 */}
                <div className=" text-black p-5 pt-3 lg:pt-3 flex flex-col items-center shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-md my-8 md:my-0">
                  <div className="mb-4">
                    {/* Replace with your image path */}
                    <img
                      src="/automation2.png"
                      alt="Consistency Icon"
                      className="w-14 h-14 md:w-24 md:h-24"
                    />
                  </div>
                  <h2 className="text-base md:text-lg font-medium md:font-semibold mb-2 text-center text-[#251230] border-[1px] border-[#251230] rounded-xl px-2 py-2 md:px-7 md:py-5 min-h-20 w-full lg:min-h-32 flex items-center justify-center">
                    Impact on Messaging Consistency
                  </h2>
                  <p className="text-sm text-center">
                    The absence of automation leads to inconsistent messaging,
                    which can affect brand perception and customer engagement.
                  </p>
                </div>

                {/* Card 3 */}
                <div className=" text-black p-5 pt-3 lg:pt-3 flex flex-col items-center shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-md">
                  <div className="mb-4">
                    {/* Replace with your image path */}
                    <img
                      src="/automation3.png"
                      alt="Opportunities Icon"
                      className="w-14 h-14 md:w-24 md:h-24"
                    />
                  </div>
                  <h2 className="text-base md:text-lg font-medium md:font-semibold mb-2 text-center text-[#251230] border-[1px] border-[#251230] rounded-xl px-2 py-2 md:px-7 md:py-5 min-h-20 w-full lg:min-h-32 flex items-center justify-center">
                    Missed Opportunities
                  </h2>
                  <p className="text-sm text-center">
                    Cumbersome communication processes can result in missed
                    opportunities for sales and customer interaction.
                  </p>
                </div>
              </div>
            </div>

            {/* Right Container */}
            <div className="lg:w-[30%] items-center justify-center bg-[#251230] p-10 hidden lg:flex rounded-sm min-h-[420px] max-h-full">
                <h1 className="text-2xl font-semibold text-white text-center">
                  The Need for Automation in Retail Communication
                </h1>
            </div>
          </div>
        </div>

        <div className="bg-[#251230] text-white border-t border-[#251230] w-full">
          {/* Title */}
          <div className="bg-[#EDE8EE] w-full">
            <div className="text-center capitalize p-4 max-w-screen-xl xl:max-w-screen-2xl mx-auto">
              <h1 className="text-xl md:text-3xl font-medium md:font-semibold text-[#251230]">
                Issues with Manual Processes
              </h1>
            </div>
          </div>
          {/* Content Grid */}
          <div className="max-w-screen-xl xl:max-w-screen-2xl py-12 md:my-12 px-4 mx-auto grid grid-cols-1 lg:grid-cols-2 gap-10">
            <div className="flex items-center space-x-4 md:px-5">
              <div className="flex-shrink-0">
                <img
                  src="/manualProcess1.png"
                  alt="Struggle Icon"
                  className="w-10 h-10 md:w-14 md:h-14"
                />
              </div>
              <div className="w-full">
                <div className="text-lg font-semibold bg-[#EA688A] px-2 md:px-5 md:pl-2 py-1 md:py-2 flex items-center rounded-md gap-2 md:gap-5 w-full">
                  <div className="px-1 py-0.5 rounded-full border-[1px] md:w-8 md:h-8 flex items-center justify-center border-white text-xs md:text-base font-normal">
                    <p>01</p>
                  </div>
                  <h2 className="text-sm font-normal md:text-lg md:font-semibold">
                    Struggle to Inform Customers
                  </h2>
                </div>
                <p className="text-xs md:text-sm mt-2">
                  Retailers face challenges in keeping customers informed in
                  real time, leading to a decreased sense of urgency and
                  excitement that drives conversions.
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-4 md:px-5">
              <div className="flex-shrink-0">
                <img
                  src="/manualProcess2.png"
                  alt="Inefficiency Icon"
                  className="w-10 h-10 md:w-14 md:h-14"
                />
              </div>
              <div className="w-full">
                <div className="text-lg font-semibold bg-[#EA688A] px-2 md:px-5 md:pl-2 py-1 md:py-2 flex items-center rounded-md gap-2 md:gap-5 w-full">
                  <div className="px-1 py-0.5 rounded-full border-[1px] md:w-8 md:h-8 flex items-center justify-center border-white text-xs md:text-base font-normal">
                    <p>02</p>
                  </div>
                  <h2 className="text-sm font-normal md:text-lg md:font-semibold">
                    Inefficiency of Manual Processes
                  </h2>
                </div>
                <p className="text-xs md:text-sm mt-2">
                  Manual processes often lead to delays, resulting in
                  inefficient and irrelevant notifications.
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-4 md:px-5">
              <div className="flex-shrink-0">
                {/* Add your image here */}
                <img
                  src="/manualProcess3.png"
                  alt="Marketing Icon"
                  className="w-10 h-10 md:w-14 md:h-14"
                />
              </div>
              <div className="w-full">
                <div className="text-lg font-semibold bg-[#EA688A] px-2 md:px-5 md:pl-2 py-1 md:py-2 flex items-center rounded-md gap-2 md:gap-5 w-full">
                  <div className="px-1 py-0.5 rounded-full border-[1px] md:w-8 md:h-8 flex items-center justify-center border-white text-xs md:text-base font-normal">
                    <p>03</p>
                  </div>
                  <h2 className="text-sm font-normal md:text-lg md:font-semibold">
                    Impact on Marketing Effectiveness
                  </h2>
                </div>
                <p className="text-xs md:text-sm mt-2">
                  The inefficiencies in manual processes reduce the
                  effectiveness of marketing efforts, affecting overall customer
                  engagement.
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-4 md:px-5">
              <div className="flex-shrink-0">
                <img
                  src="/manualProcess4.png"
                  alt="Disconnection Icon"
                  className="w-10 h-10 md:w-14 md:h-14"
                />
              </div>
              <div className="w-full">
                <div className="text-lg font-semibold bg-[#EA688A] px-2 md:px-5 md:pl-2 py-1 md:py-2 flex items-center rounded-md gap-2 md:gap-5 w-full">
                  <div className="px-1 py-0.5 rounded-full border-[1px] md:w-8 md:h-8 flex items-center justify-center border-white text-xs md:text-base font-normal">
                    <p>04</p>
                  </div>
                  <h2 className="text-sm font-normal md:text-lg md:font-semibold">
                    Customer Disconnection
                  </h2>
                </div>
                <p className="text-xs md:text-sm mt-2">
                  Customers are left disconnected from key brand moments due to
                  ineffective communication, undermining brand loyalty.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[#251230] text-white border-t border-[#251230] w-full h-auto">
          <div className="bg-[#EDE8EE] w-full">
            <div className="text-center capitalize p-4 max-w-screen-xl xl:max-w-screen-2xl mx-auto">
              <h1 className="text-xl md:text-3xl font-medium md:font-semibold text-[#251230]">
                Introduction to InfiConnect
              </h1>
            </div>
          </div>
          <div className="max-w-screen-xl xl:max-w-screen-2xl mx-auto flex flex-col-reverse md:flex-row items-center h-auto md:px-4 md:py-10">
            <div className="flex flex-col-reverse lg:flex-row justify-center items-center gap-8 px-4 py-10 w-full">
              {/* Left Content */}
              <div className="flex flex-col items-center lg:items-end text-center lg:text-right max-w-full lg:max-w-[35%] space-y-6">
                <div className="flex flex-col-reverse md:flex-col-reverse lg:flex-row items-center justify-center space-y-4 lg:space-y-0 lg:space-x-4">
                  <div>
                    <h2 className="text-lg font-semibold">
                      Leveraging Staff Connections
                    </h2>
                    <p className="text-sm">
                      The technology leverages store staff's personal
                      connections to improve customer engagement.
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <img
                      src="/inficonnectIntroduction1.png"
                      alt="Struggle Icon"
                      className="w-14 h-14"
                    />
                  </div>
                </div>
                <div className="flex flex-col-reverse md:flex-col-reverse lg:flex-row items-center justify-center space-y-4 lg:space-y-0 lg:space-x-4">
                  <div>
                    <h2 className="text-lg font-semibold">
                      Enhanced In-Store Experience
                    </h2>
                    <p className="text-sm">
                      InfiConnect enhances the in-store experience by providing
                      personalized offerings and timely information.
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <img
                      src="/inficonnectIntroduction2.png"
                      alt="Struggle Icon"
                      className="w-14 h-14"
                    />
                  </div>
                </div>
              </div>

              {/* Center Image */}
              <div className="relative w-40 h-40 md:w-64 md:h-64 rounded-full flex items-center justify-center">
                <img
                  src="/inficonnectIntroductionMain.png"
                  alt="Center Icon"
                  className="w-full h-full object-cover"
                />
              </div>

              {/* Right Content */}
              <div className="flex flex-col items-center lg:items-start text-center lg:text-left max-w-full lg:max-w-[35%] space-y-6">
                <div className="flex flex-col lg:flex-row items-center justify-center space-y-4 lg:space-y-0 lg:space-x-4">
                  <div className="flex-shrink-0">
                    <img
                      src="/inficonnectIntroduction3.png"
                      alt="Struggle Icon"
                      className="w-14 h-14"
                    />
                  </div>
                  <div className="mb-4 lg:mb-8">
                    <h2 className="text-lg font-semibold">
                      Robust Tech Solution
                    </h2>
                    <p className="text-sm">
                      Inficonnect is designed to be a robust yet handy
                      technology solution for retailers.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row items-center justify-center space-y-4 lg:space-y-0 lg:space-x-4">
                  <div className="flex-shrink-0">
                    <img
                      src="/inficonnectIntroduction4.png"
                      alt="Struggle Icon"
                      className="w-14 h-14"
                    />
                  </div>
                  <div className="mb-4 lg:mb-8">
                    <h2 className="text-lg font-semibold">
                      Tailored Real-Time Updates
                    </h2>
                    <p className="text-sm">
                      This solution enables retailers to deliver tailored,
                      real-time updates to their customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 
      <div className="bg-gray-100 py-12 flex flex-col items-center">
        <h1 className="text-3xl font-semibold mb-8 text-gray-800">Pricing Plans</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-6xl">
          <div className="rounded-lg shadow-lg p-6 text-white bg-teal-500">
            <h2 className="text-2xl font-bold mb-4">Basic</h2>
            <p className="text-sm mb-4">Per User</p>
            <div className="flex justify-between items-center mb-6">
              <div>
                <p className="text-lg font-semibold">Monthly</p>
                <p className="text-xl font-bold">₹125</p>
              </div>
              <div>
                <p className="text-lg font-semibold">Annual</p>
                <p className="text-xl font-bold">₹100</p>
              </div>
            </div>
            <ul className="text-sm space-y-2">
              <li className="flex items-start">
                <span className="mr-2">✔️</span> Super Admin
              </li>
              <li className="flex items-start">
                <span className="mr-2">✔️</span> Three Levels: Super Admin > Admin > User
              </li>
              <li className="flex items-start">
                <span className="mr-2">✔️</span> Templates, Basic Analytics, WhatsApp, Email Integration
              </li>
              <li className="flex items-start">
                <span className="mr-2">✔️</span> Support center
              </li>
              <li className="flex items-start">
                <span className="mr-2">✔️</span> Max. no. of Catalogues/user/month = 50
              </li>
            </ul>
          </div>

          <div className="rounded-lg shadow-lg p-6 text-white bg-blue-500">
            <h2 className="text-2xl font-bold mb-4">Standard</h2>
            <p className="text-sm mb-4">Per User</p>
            <div className="flex justify-between items-center mb-6">
              <div>
                <p className="text-lg font-semibold">Monthly</p>
                <p className="text-xl font-bold">₹200</p>
              </div>
              <div>
                <p className="text-lg font-semibold">Annual</p>
                <p className="text-xl font-bold">₹150</p>
              </div>
            </div>
            <ul className="text-sm space-y-2">
              <li className="flex items-start">
                <span className="mr-2">✔️</span> Basic +
              </li>
              <li className="flex items-start">
                <span className="mr-2">✔️</span> AI Based Chatbot for Insights
              </li>
              <li className="flex items-start">
                <span className="mr-2">✔️</span> AI Based Catalog Curation
              </li>
              <li className="flex items-start">
                <span className="mr-2">✔️</span> Max. no. of Catalogues/user/month = 150
              </li>
            </ul>
          </div>

          <div className="rounded-lg shadow-lg p-6 text-white bg-purple-500">
            <h2 className="text-2xl font-bold mb-4">Enterprises</h2>
            <p className="text-sm mb-4">Per User</p>
            <div className="flex justify-between items-center mb-6">
              <div>
                <p className="text-lg font-semibold">Monthly</p>
                <p className="text-xl font-bold">₹250</p>
              </div>
              <div>
                <p className="text-lg font-semibold">Annual</p>
                <p className="text-xl font-bold">₹185</p>
              </div>
            </div>
            <ul className="text-sm space-y-2">
              <li className="flex items-start">
                <span className="mr-2">✔️</span> Standard +
              </li>
              <li className="flex items-start">
                <span className="mr-2">✔️</span> Coupon Mgt.
              </li>
              <li className="flex items-start">
                <span className="mr-2">✔️</span> Loyalty Mgt.
              </li>
              <li className="flex items-start">
                <span className="mr-2">✔️</span> Max. no. of Catalogues/user/month = Unlimited
              </li>
            </ul>
          </div>
        </div>
      </div> */}

        <div className="bg-gradient-to-r from-purple-300 via-pink-300 to-blue-300 xl:min-h-screen flex items-center justify-center py-10 px-4 w-full">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 max-w-screen-xl xl:max-w-screen-2xl w-full">
            <div className="relative bg-white rounded-lg shadow-lg p-6 text-center transition-transform hover:scale-105">
              <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 w-12 h-12 bg-orange-100 rounded-full flex items-center justify-center">
                <span role="img" aria-label="fox">
                  🦊
                </span>
              </div>
              <div className="absolute top-0 right-0 bg-blue-500 text-white text-sm font-semibold px-3 py-1 rounded-bl-lg">
                ₹125/month
              </div>
              <h3 className="text-lg font-bold mt-8">Basic</h3>
              <p className="text-sm text-gray-500 mb-4">
                Complementary Features
              </p>
              <ul className="text-sm text-gray-700 space-y-2 mb-6 w-full lg:min-h-44">
                <li className="sm:w-[65%] w-[75%] ml-auto text-left flex items-start"><span className="mr-2 text-green-600 mt-1"><FaCheck /></span> <span className="inline-flex">Super Admin</span></li>
                <li className="sm:w-[65%] w-[75%] ml-auto text-left flex items-start"><span className="mr-2 text-green-600 mt-1"><FaCheck /></span> <span className="inline-flex">Support Center</span></li>
                <li className="sm:w-[65%] w-[75%] ml-auto text-left flex items-start"><span className="mr-2 text-green-600 mt-1"><FaCheck /></span> <span className="inline-flex">Templates, Basic Analytics</span></li>
                <li className="sm:w-[65%] w-[75%] ml-auto text-left flex items-start"><span className="mr-2 text-green-600 mt-1"><FaCheck /></span> <span className="inline-flex">WhatsApp & Email Integration</span></li>
                <li className="sm:w-[65%] w-[75%] ml-auto text-left flex items-start"><span className="mr-2 text-green-600 mt-1"><FaCheck /></span> <span className="inline-flex">Max. Catalogues: 50/user/month</span></li>
                <li className="sm:w-[65%] w-[75%] ml-auto text-left flex items-start"><span className="mr-2 text-green-600 mt-1"><FaCheck /></span> <span className="inline-flex">Three Levels: Super Admin &gt; Admin &gt; User</span></li>
              </ul>
              <button className="bg-blue-500 text-white font-medium px-4 py-2 rounded-full hover:bg-blue-600">
                Buy Now
              </button>
            </div>

            <div className="relative bg-white rounded-lg shadow-lg p-6 text-center transition-transform hover:scale-105 my-4 lg:my-0">
              <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center">
                <span role="img" aria-label="panda">
                  🐼
                </span>
              </div>
              <div className="absolute top-0 right-0 bg-purple-500 text-white text-sm font-semibold px-3 py-1 rounded-bl-lg">
                ₹200/month
              </div>
              <h3 className="text-lg font-bold mt-8">Standard</h3>
              <p className="text-sm text-gray-500 mb-4">
                Basic + Additional Features
              </p>
              <ul className="text-sm text-gray-700 space-y-2 mb-6 w-full lg:min-h-44">
                <li className="sm:w-[65%] w-[75%] ml-auto text-left flex items-start"><span className="mr-2 text-green-600 mt-1"><FaCheck /></span><span className="inline-flex"> AI-Based Chatbot for Insights</span></li>
                <li className="sm:w-[65%] w-[75%] ml-auto text-left flex items-start"><span className="mr-2 text-green-600 mt-1"><FaCheck /></span><span className="inline-flex"> AI-Based Catalog Curation</span></li>
                <li className="sm:w-[65%] w-[75%] ml-auto text-left flex items-start"><span className="mr-2 text-green-600 mt-1"><FaCheck /></span><span className="inline-flex"> Max. Catalogues: 150/user/month</span></li>
              </ul>
              <button className="bg-purple-500 text-white font-medium px-4 py-2 rounded-full hover:bg-purple-600">
                Buy Now
              </button>
            </div>

            <div className="relative bg-white rounded-lg shadow-lg p-6 text-center transition-transform hover:scale-105">
              <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 w-12 h-12 bg-yellow-100 rounded-full flex items-center justify-center">
                <span role="img" aria-label="unicorn">
                  🦄
                </span>
              </div>
              <div className="absolute top-0 right-0 bg-yellow-500 text-white text-sm font-semibold px-3 py-1 rounded-bl-lg">
                ₹250/month
              </div>
              <h3 className="text-lg font-bold mt-8">Enterprise</h3>
              <p className="text-sm text-gray-500 mb-4">
                Standard + Premium Features
              </p>

              <ul className="text-sm text-gray-700 space-y-2 mb-6 w-full lg:min-h-44">
                <li className="sm:w-[65%] w-[75%] ml-auto text-left flex items-start"><span className="mr-2 text-green-600 mt-1"><FaCheck /></span><span className="inline-flex"> Coupon Management</span></li>
                <li className="sm:w-[65%] w-[75%] ml-auto text-left flex items-start"><span className="mr-2 text-green-600 mt-1"><FaCheck /></span><span className="inline-flex"> Loyalty Management</span></li>
                <li className="sm:w-[65%] w-[75%] ml-auto text-left flex items-start"><span className="mr-2 text-green-600 mt-1"><FaCheck /></span><span className="inline-flex"> Max. Catalogues: Unlimited</span></li>
              </ul>
              <button className="bg-yellow-500 text-white font-medium px-4 py-2 rounded-full hover:bg-yellow-600">
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Inficonnect;

function FeatureCard({ title, description, icon }) {
  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105">
      <div className="text-4xl">{icon}</div>
      <h3 className="text-xl font-semibold text-gray-800 mt-4">{title}</h3>
      <p className="text-gray-600 mt-2">{description}</p>
    </div>
  );
}
