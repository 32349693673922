import React, { useState } from "react";


const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
  };

  return (
    // <div className="min-h-screen flex items-center justify-center md:bg-gray-100 md:p-8">
    <div
      className="md:min-h-screen flex items-center justify-end  bg-cover bg-center bg-no-repeat md:p-8"
      style={{ backgroundImage: `url('/contactusBackground.png')` }}
    >
      <div className="bg-gradient-to-br from-[#e3f5fd] to-[#bee0ec] w-full max-w-4xl p-4 shadow-sm md:p-8">
        <h2 className="text-3xl text-[#89bace] font-semibold text-center">Contact Us</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Name */}
          <div>
            <label className="block text-gray-700 py-2">Name :</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-slate-200"
              placeholder="Enter your name"
              required
            />
          </div>

          {/* Email */}
          <div>
            <label className="block text-gray-700  py-2">Email :</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-slate-200"
              placeholder="Enter your email"
              required
            />
          </div>

          {/* Subject */}
          <div>
            <label className="block text-gray-700  py-2">Subject :</label>
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-slate-200"
              placeholder="Subject"
              required
            />
          </div>

          {/* Message */}
          <div>
            <label className="block text-gray-700  py-2">Message :</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-slate-200"
              placeholder="Your message"
              rows="4"
              required
            ></textarea>
          </div>

          {/* Submit Button */}
          <div className="text-center">

            <button
              type="submit"
              className="w-full md:w-[50%] bg-[#89bace] text-white py-2 rounded-md hover:bg-[#81bad0] transition duration-300"
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactSection;
