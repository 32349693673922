import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineChevronRight } from "react-icons/md";

const BrandSection = () => {
  const imagesSlider1 = ["/slider1.png", "/slider2.png", "/slider3.png"];

  const imagesSlider2 = ["/slider2.png", "/slider3.png", "/slider1.png"];

  const [offset1, setOffset1] = useState(0);
  const [offset2, setOffset2] = useState(0);
  const [direction1, setDirection1] = useState("down");
  const [direction2, setDirection2] = useState("up");

  const logos = [
    { id: 1, href: "#", imgSrc: "/brands/Frame 1.png", alt: "Brand Logo" },
    { id: 2, href: "#", imgSrc: "/brands/Frame 2.png", alt: "Brand Logo" },
    { id: 3, href: "#", imgSrc: "/brands/Frame 3.png", alt: "Brand Logo" },
    { id: 4, href: "#", imgSrc: "/brands/Frame 4.png", alt: "Brand Logo" },
    { id: 5, href: "#", imgSrc: "/brands/Frame 5.png", alt: "Brand Logo" },
    { id: 6, href: "#", imgSrc: "/brands/Frame 6.png", alt: "Brand Logo" },
    { id: 7, href: "#", imgSrc: "/brands/Frame 7.png", alt: "Brand Logo" },
    { id: 8, href: "#", imgSrc: "/brands/Frame 8.png", alt: "Brand Logo" },
    { id: 9, href: "#", imgSrc: "/brands/Frame 9.png", alt: "Brand Logo" },
    { id: 10, href: "#",imgSrc: "/brands/Frame 10.png",alt: "Brand Logo" },
  ];

  const [translateX, setTranslateX] = useState(0);

  // Width calculation for the entire slider (to accommodate both original and duplicated logos)
  const logoWidth = 200; // Adjust the logo width (in px)
  const totalWidth = logoWidth * logos.length * 2; // Multiply by 2 for the duplicate logos

  useEffect(() => {
    const interval = setInterval(() => {
      setTranslateX((prev) => prev - 1); // Adjust this value for speed (negative to scroll left)
    }, 10); // Time interval for smooth scrolling

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // When the first set of logos has fully scrolled off, reset to the start (without any gap)
    if (Math.abs(translateX) >= totalWidth / 2) {
      setTranslateX(0); // Reset position for a seamless loop
    }
  }, [translateX]);

  useEffect(() => {
    const interval = setInterval(() => {
      setOffset1((prevOffset) => {
        const nextOffset =
          direction1 === "down" ? prevOffset + 1 : prevOffset - 1;
        if (nextOffset > imagesSlider1.length * 200 - 90) setDirection1("up");
        if (nextOffset < 0) setDirection1("down");
        return nextOffset;
      });

      setOffset2((prevOffset) => {
        const nextOffset =
          direction2 === "down" ? prevOffset + 1 : prevOffset - 1;
        if (nextOffset > imagesSlider2.length * 200 - 90) setDirection2("up");
        if (nextOffset < 0) setDirection2("down");
        return nextOffset;
      });
    }, 10);

    return () => clearInterval(interval);
  }, [direction1, direction2, imagesSlider1.length, imagesSlider2.length]);

  return (
    <div className="px-4 max-w-screen-xl xl:max-w-screen-2xl mx-auto">
      <section class="bg-whitet">
        <div className="overflow-hidden relative">
          <div
            className="flex gap-8 px-4"
            style={{
              transform: `translateX(${translateX}px)`,
              transition: "transform 0s linear", // Instant reset for seamless loop
              width: `${totalWidth}px`, // Set the width to accommodate both the original and duplicated logos
            }}
          >
            {/* Display the logos twice (for seamless looping) */}
            {[...logos, ...logos].map((logo, index) => (
              <a
                key={index} // Use index for duplicated items
                className="flex items-center"
                href={logo.href}
              >
                <img
                  src={logo.imgSrc}
                  alt={logo.alt}
                  className="h-12 md:h-32 w-auto object-contain" // Adjust size of the images
                />
              </a>
            ))}
          </div>
        </div>

        {/* </div> */}
      </section>
    </div>
  );
};

export default BrandSection;
